﻿.products {
  text-align: left;

  .product {

    .inner {

      h3 {
        margin: 0 0 8px;
        font-size: 38px;
        font-family: Gilroy-Light, sans-serif;
      }

      label {
        font-size: 24px;
        font-family: Gilroy-SemiBold, sans-serif;
        color: #f07d00;
      }

      p {
        font-size: 20px;
        margin: 8px 0 0;
        text-align: left;
      }

      p.price {
        font-size: 24px;
        font-family: Gilroy-SemiBold, sans-serif;
        color: #f07d00;
      }

      @include breakpoint(max-tablet-portrait) {
        img {
          max-width: 113px;
        }
      }
    }
  }
}

.productblock-inner {

  .info {
    color: $white;

    ul li:before {
      color: $white !important;
    }

    p:first-of-type {
      font-size: 1rem;

      @include breakpoint(tablet-portrait) {
        font-size: 1.125rem;
      }

      @include breakpoint(desktop) {
        font-size: 1.25rem;
      }
    }
  }

  .order-form {

    h3 {
      color: $white;
      font-size: 20px;
      padding: 0;
      text-align: center;
    }

    p {
      color: $white;
      text-align: center;
      margin: 0 0 16px;
    }
    .price {
      background: url(/Static/img/bg-splash.png) no-repeat;
      background-size: 65px 75px;
      width: 65px;
      height: 75px;
      position: absolute;
      top: -50px;
      right: 10px;
      color: $white;
      text-align: center;

      .number {
        font-size: 32px;
        font-family: Gilroy-SemiBold, sans-serif, sans-serif;
        line-height: 32px;
        display: inline-block;
        margin-top: 13px;
      }

      .description {
        font-size: 12px;
        line-height: 12px;
      }

      @include breakpoint(tablet-landscape) {
        top: -100px;
        right: -55px;
        background-size: 130px 150px;
        width: 130px;
        height: 150px;
        .number {
          font-size: 52px;
          line-height: 54px;
          margin-top: 30px;
        }
        .description {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
    input {
      @include placeholder {
        font-style: italic;
        color: $gray;
      }
    }

    button {
      font-size: 16px;
      font-family: Gilroy-SemiBold, sans-serif, sans-serif;
    }
  }
}

.productinfo {

  h2 {
    font-size: 18px;
    font-family: Gilroy-SemiBold, sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #9c9c9c;
    margin-bottom: 20px;
    @include breakpoint(max-tablet-portrait) {
      font-size: 16px;
    }
  }


  .included-column {
    text-align: center;

    h3 {
      font-size: 24px;
      font-family: Gilroy-Semibold, sans-serif;
      color: #454545;
      margin: 1rem 0 0.5rem;
      @include breakpoint(max-tablet-portrait) {
        margin: 10px 0 2px;
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      color: #454545;
      margin: 0;
    }

  }
}
