﻿footer {
  background-color: $night-rider;
  color: $white;

  a {
    color: $light-smoke;

    @include attention() {
      color: $white;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 0.75rem;
    }
  }

  .footer__icon-wrapper {
    background: $emperor;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    @include border-radius(1rem);

    @include attention() {
      color: $orangegold;
      background: $white;
    }

    i {
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* Center the icon */
    }
  }

  .footer__icontext-wrapper {
    display: flex;
    background: $emperor;
    align-items: center;
    height: 32px;
    border-radius: 1.25rem;
    padding: 0 0.75rem;

    @include attention() {
      color: $orangegold;
      background: $white;

      i {
        text-decoration: none;
      }
    }

    i {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .footer__iconlink-wrapper {
    display: inline-flex;
    align-items: center;
    text-align: center;

    i {
      font-size: 20px;
      width: 32px;
      height: 32px;
      @include border-radius(1rem);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include attention() {
      color: $white;
      text-decoration: none;

      i {
        color: $orangegold;
        background: $white;
      }
    }
  }

  .footer__logo {
    width: 71px;
    height: 32px;
  }

  .footer__logo-text {
    @include breakpoint(max-phone) {
      font-size: 14px;
    }
  }

  .footer__bottom-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &:hover{
      text-decoration:underline;
  }
  }

  .footer__bottom-link {
    &:not(:last-child) {
      margin-right: 0.5rem;

      &:after {
        content: "\00B7";
        margin-left: 0.5rem;
      }
    }

    @include breakpoint(desktop) {
      &:not(:last-child) {
        margin-right: 0.75rem;

        &:after {
          margin-left: 0.75rem;
        }
      }
    }

    @include attention() {
      &:after {
        display: inline-block;
      }
    }
  }
}