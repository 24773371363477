﻿.site-header {
  @include clearfix;
  background: $night-rider;
  top: 0;
  transition: top 0.25s ease-in-out;
  width: 100%;
  z-index: 210;

  &.position-md-sticky {
    @include breakpoint(max-tablet-portrait) {
      top: -1px;
      position: sticky;
    }
  }

  .fas.fa-life-ring {
    background: none;
    margin-right: 0.5rem;
  }

  @include breakpoint(max-tablet-portrait) {
    .container {
      padding-left: 10px;
      padding-right: 10px;
    }

    .submenu {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .site-header__logo {
    width: 77px;
    height: 40px;
    display: block;
    float: left;
    background: $white;
    text-align: center;
    background: url(/Static/svg/UC_white.svg) no-repeat;
    text-indent: -9999px;
    @include breakpoint(max-tablet-portrait) {
      height: 33px;
    }
  }

  .site-header__top,
  .site-header__sub {

    .site-header__switch {
      @include breakpoint(max-tablet-portrait) {
        padding: 5px 5px 5px 10px;
      }

      @include breakpoint(max-phone) {
        a {
          font-size: 0.7rem;
        }
      }
    }

    .site-header__switch-link {
      margin: 0;
      height: 44px;
      padding: 0 20px;
      line-height: 44px;
      display: inline-block;
      color: $tahiti-semi;
      font-size: 0.875em;
      font-family: Gilroy-SemiBold;

      &:first-child {
        color: #eaeaea;
      }

      @include breakpoint(max-tablet-portrait) {
        line-height: 1;
        height: auto;
        padding: 5px;
      }
    }

    .site-header__switch-link--active {
      color: $tahiti-gold;
    }
  }

  .site-header__top {
   

    @include breakpoint(max-tablet-portrait) {
      padding: 10px 0;
      background: $night-rider;

      .site-header__switch-link--active {
        padding-left: 10px;
      }
    }

    @include breakpoint(tablet-portrait) {
      height: 44px;
    }

    background-color: $emperor;

    .site-header__switch {
      display: inline-block;
    }

    .site-header__switch-link--active {
      background-color: $night-rider;
      border-left: 1px solid #666;
    }
  }

  .site-header__sub {
    width: 100%;
    padding: 15px 0;
    margin-bottom: 0;

    @include breakpoint(max-tablet-portrait) {
      display: none;
    }

    ul {
      > li {
        @include attention() {
          a {
            text-decoration: none;
          }
        }

        &.active {
          > a {
            color: $tahiti-gold;
          }

          ul > li.active {
            > a {
              color: $tahiti-gold;
            }
          }
        }

        > a {
          color: $white;

          @include attention() {
            color: $tahiti-gold;
            background: none;
          }
        }

        &.parent {
          @include attention() {
            > .dropdown-menu {
              display: block;

              .parent {
                position: relative;

                > a {
                  padding-right: 30px;
                }

                i {
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                }

                .dropdown-menu {
                  top: 0;
                  left: 100%;
                  margin-top: -1px;
                }
              }
            }
          }
        }

        a {
          padding: 8px 10px;
          display: block;
        }

        .dropdown-menu {
          left: auto;
          top: auto;
          margin-top: 0;
          white-space: nowrap;

          a {

            @include attention() {
              background-color: $tahiti-gold;
              color: $white;

              i {
                color: $white;
              }
            }
          }

          /*ASK DESIGN IF ACTIVE IS NEEDED AT ALL FOR THIS PURPOSE*/
          .active a {
            background-color: $eclipse;
            color: $white;

            @include attention() {
              background-color: $tahiti-gold;
              color: $white;
            }
          }
        }
      }

      &.navbar-right {
        li:last-child {
          a {
            padding-right: 0;
          }
        }
      }
    }

    .site-header__switch {
      float: left;
    }

    .site-header__switch-link--active {
      border-left: 1px solid $white-gray;
    }
  }

  .dropdown-menu {
    background: $eclipse;

    li a {
      color: $white;

      @include attention() {
        background-color: $tahiti-gold;
      }
    }
  }

  .site-header__quicknav {

    @include breakpoint(max-tablet-portrait) {
      display: inline-block;
      margin-left: 0.75rem;
    }

    @include breakpoint(max-phone) {
      margin-left: 0;
    }

    button {
      display: none;
      border: 0;
      outline: 0;
      background: none;
      color: $alto;
      font-size: 0.875rem;
      font-family: Gilroy-SemiBold, sans-serif;
      padding: 0;

      @include attention() {
        color: $white;
      }

      .fa {
        margin-left: 0.25rem;
      }

      @include breakpoint(max-tablet-portrait) {
        display: inline-block;
      }
    }

    .current__quicknavlinks {
      padding: 10px 0;
      margin: 0;

      > li {
        display: inline-block;
        border-right: 1px solid $gray;
        margin-right: 0.75rem;
        padding-right: 1rem;

        &:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }

        > a {
          color: $alto;

          @include attention() {
            color: $white;
            background: none;
          }
        }

        @include breakpoint(max-tablet-portrait) {
          display: block;
          background-color: $white;
        }
      }

      > .label {
        display: none;
      }

      > .active {
        > a {
          color: $white;
          background: none;
        }
      }
    }

    .dropdown-menu {

      @include breakpoint(max-phone) {
        > li {
          margin: 2px 0;

          > a {
            padding: 0.5rem 0.75rem;
            font-size: 0.875rem;

            @include attention() {
              background-color: $tahiti-gold;
              color: $white;
            }
          }
        }

        > .label {
          font-size: 0.75rem;
          font-family: Gilroy-SemiBold, sans-serif;
          color: $emperor;
          padding: 0.25rem 0.75rem;
        }

        > .active {
          > a {
            color: $tahiti-gold;
            background: $white;

            @include attention() {
              color: $tahiti-gold;
              background: #fef9f2;
            }
          }
        }
      }
    }
  }

  .site-header__quicknav-trigger {
    color: $white;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 0.875rem;

    @include breakpoint(tablet-portrait) {
      display: none;
    }
  }

  .site-header__quicknav-links {
    @include breakpoint(max-tablet-portrait) {
      display: none;
    }

    display: inline-block;
  }

  .site-header__lang,
  .site-header__nav {
    float: right;
    margin-top: 0;
  }

  .site-header__nav-link {
    margin-left: 1rem;
    display: inline-block;
    line-height: 44px;
    float: left;
    color: $white;

    &:first-child {
      margin-left: 0;
    }

    .icon-text {
      display: none;
    }

    .icon {
      color: $tahiti-gold;
    }

    @include attention() {
      color: $tahiti-gold;
      text-decoration: none;

      .icon {
        color: $tahiti-gold;
      }
    }

    @include breakpoint(max-tablet-portrait) {
      margin-top: -5px;
      display: inline-block;
      font-size: 24px;
      line-height: 40px;
      width: 40px;
      height: 40px;
      text-align: center;
      vertical-align: bottom;
      float: left;
      color: $white;
      position: relative;
      background: $dawn-pink;

      &.digitalmailbox__nav {
        background: none;
      }

      @include border-radius(0.125rem);

      .icon {
        float: none;
        width: 100%;
      }
    }

    @include breakpoint(max-phone) {
      margin-left: 0.25rem;
    }

    @include breakpoint(tablet-portrait) {
      margin-left: 0.875rem;

      .icon {
        margin-right: 0.5rem;
        font-size: 1.25rem;
        width: auto;
        height: auto;
        position: relative;
        top: 1px;
      }

      .icon-text {
        display: inline-block;
        font-size: 1rem;
        font-family: Gilroy-SemiBold, sans-serif;
      }
    }
  }

  .site-header__close {
    display: none;
    float: right;
    margin: 13px 0 0;
    cursor: pointer;
    text-shadow: none;
    color: $mercury;
    opacity: 1;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 1rem;
    text-decoration: none;

    .icon {
      float: none;
      display: inline-block;
      margin-left: 4px;
      color: $mercury;
      font-size: 1rem;
    }

    @include breakpoint(max-tablet-portrait) {
      margin-top: 0;
    }

    @include attention() {
      text-decoration: none;
      color: $white;

      .icon {
        color: $white;
      }
    }
  }

  .loggedin-top-nav {
    .btn-standard {
      border-radius: 6px;
      padding: 6px 8px;
      font-weight:400; 
      
      i {
        font-size: 14px;
        &.fa-user-lock {
          font-size: 12px;
        }
      }
    }
    .mobile-menu-bars-button {
      -webkit-appearance: none;
      @include attention() {
        text-decoration: none;
      }
    }
    i {
      font-size: 12px;
    }

    a {
      padding: 0.2rem 0.6rem;
      
      .mobile-menu-bars {
        gap: 20px;
        width: 56px;
        height: 33px;
        background-color: $dark-ocean;
        border-radius: 100px;
        &.isLoggedIn {
          background-color: $orangegold;
        }
      }
    }
  }

  .site-header__signedin,
  .site-header__login {

    float: right;
    margin-left: 1rem;

    @include breakpoint(max-phone) {
      margin-left: 0.25rem;
    }

    .btn {
      color: $white;
      border: 0;

      @include breakpoint(max-tablet-portrait) {
        margin-top: -5px;
        padding: 0;
      }

      @include attention() {
        /*background: darken($muddy-waters,5%);*/
      }
      /*i {
          margin-left: 6px;
      }*/

      .icon {
        i {
          margin: 0;
        }
      }

      .text {
        font-size: 14px;
      }
    }

    .icon {
      display: inline-block;
      font-size: 24px;
      line-height: 40px;
      width: 40px;
      height: 40px;
      text-align: center;
      vertical-align: bottom;
      float: left;
      text-align: center;
      color: $white;
      position: relative;
    }

    .icon-text {
      display: none;
    }

    li.signout-item {
      margin: 0.5rem;

      .btn {
        padding: 0.25rem 0;
      }
    }

    @include breakpoint(tablet-portrait) {
      margin-left: 0.875rem;

      .icon {
        margin-right: 0.5rem;
        display: none;
      }

      .fa,
      .icon-text {
        display: inline-block;
      }
    }
  }

  .site-header__lang {
    display: none;

    @include breakpoint(tablet-portrait) {
      display: block;
    }
  }

  .site-header__lang-list {
    list-style: none;
    margin: 0 0 0 1.5rem;
    padding: 0;
    float: left;

    &:before {
      content: "\f0ac";
      font-family: "Font Awesome 5 Pro";
      position: relative;
      top: 1px;
      float: left;
      color: $muddy-waters;
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }
  }

  .site-header__lang-item {
    display: block;
    float: left;
    margin: 0.3125rem 0 0 0.5rem;
    padding: 0 0 0 0.5rem;
    border-left: 1px solid $gallery;
    text-transform: capitalize;
    line-height: 20px;
    position: relative;
    top: 1px;

    &:first-child {
      margin: 0.3125rem 0 0 0;
      padding: 0;
      border-left: 0;
    }

    a {
      color: $gray;
    }
  }

  .site-header__lang-item--active {
    font-family: Gilroy-SemiBold, sans-serif;
    top: 0;
    color: $white;
  }


  .site-header__userdropdown {
    .dropdown-menu {
      background: $white;
    }

    .btn-userdropdown {
      @include breakpoint(max-tablet-portrait) {
        display: inline-block;
        font-size: 24px;
        line-height: 40px;
        width: 40px;
        height: 40px;
        text-align: center;
        vertical-align: bottom;
        float: left;
        color: $white;
        position: relative;
      }

      .usericon {
        i {
          margin: 0;
        }
      }

      .firstname {
        margin: 0;
      }
    }

    .dropdown-menu {

      > li {
        > a {
          padding: 0.5rem 0.75rem;
          font-size: 0.875rem;
          color: $black;
          display: block;

          @include attention() {
            background: rgba($tahiti-gold, 1);
            color: $white;
            text-decoration: none;
          }
        }

        > .btn-default {
          padding: 0.5rem 0;
          color: $white;

          @include attention() {
            background: $christine;
          }
        }
      }

      .userinfo {
        margin-bottom: 0.25rem;
        border-bottom: 1px solid $mercury;
        padding: 0.125rem 0.5rem 0.5rem;
        font-size: 0.875rem;

        > span {
          font-size: 0.75rem;
          display: block;
          font-family: Gilroy-SemiBold, sans-serif;
        }

        @include breakpoint(tablet-portrait) {
          display: none;
        }
      }

      .signout {
        margin-top: 0.25rem;
        border-top: 1px solid $mercury;
        padding: 0.5rem 0.5rem 0.25rem;
      }
    }
  }


  @include breakpoint(max-tablet-portrait) {
    .site-header__switch-link {
      font-size: 0.875em;
    }

    .site-header__close,
    .site-header__links {
      .icon {
        /*width: 28px;Chart
height: 28px;
line-height: 28px;*/
      }
    }

    .site-header__close {
      margin-top: 0.25rem;
      font-size: 0.875em;
    }
  }

  @include breakpoint(tablet-portrait) {

    .site-header__logo {
      margin-right: 15px;
      width: 90px;
      /*height: 50px;*/
    }

    .site-header__switch-link {
      font-size: 1rem;
    }

    .site-header__signedin,
    .site-header__login {
      float: right;
      margin: 6px 0 6px 30px;
    }

    .site-header__lang,
    .site-header__nav {
      margin-top: 2px;
    }

    .site-header__nav-link {
      margin-left: 15px;
      display: inline-block;
    }

    .site-header__close {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }

  @include breakpoint(desktop) {

    .site-header__nav-link {
      margin-left: 25px;
    }
  }

  @include breakpoint(large-desktop) {

    .site-header__logo {
      margin-right: 15px;
    }

    .site-header__close {
      margin-top: 1.125rem;
    }
  }
}

.site-header--nav-up {
  top: -50px;

  @include breakpoint(tablet-portrait) {
    top: -126px;
  }

  @include breakpoint(large-desktop) {
    top: -129px;
  }
}

.overlay-visible {
  .site-header {
    border-bottom: 1px solid rgba($white, 0.1);
    z-index: 201;
    background: none;

    .site-header-main {
      background: none;
    }

    .site-header__logo {
      background: url(/Static/svg/uc-neg.svg) no-repeat;
    }

    .switch a:first-child {
      border-color: rgba($white, 0.2);
    }

    .site-header__switch-link {
      color: #b0b0b0;

      &:first-child {
        border-color: rgba($white, 0.2);
      }
    }

    .site-header__switch-link--active {
      color: $white;
      font-family: Gilroy-SemiBold;
    }

    .site-header--submenu .submenu,
    .site-header__lang,
    .site-header__nav,
    .site-header__login,
    .site-header__signedin {
      display: none !important;
    }
  }

  .submenu {
    display: none;
  }
}
