﻿.loadingscreen__wrapper {
  text-align: center;
  padding: 125px 0; 
  .spinner__wrapper {
    img {
      width: 75px;
      margin-bottom: 30px;
    }
    .spinner__loader {
      line-height: 2rem;
      font-size: 2.5rem;
      margin-bottom: 30px; 
      i {
        color: #f07d00; 
      }
    }
  }
  .spinner__text {
    font-family: Gilroy-SemiBold; 
    margin-bottom: 5px;
  }
  .spinner__description {
    margin-bottom: 30px; 
  }
  a {
    min-width: 200px; 
  }

  &.smaller { padding: 0; }
}
// FADE IN 
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
  &.one {
    -webkit-animation-delay: 0.05s;
    -moz-animation-delay: 0.05s;
    animation-delay: 0.05s;
  }
  &.two {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    animation-delay: .3s;
  }
  &.three {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    animation-delay: .6s;
  }
  &.four {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    animation-delay: .9s;
  }
  &.five {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay:1.2s;
    animation-delay: 1.2s;
  }
  // Keep adding .3s for future fade-ins
}
.faded {
  opacity: 0.3
}
.isDisabled {
  pointer-events: none;
}
.fade-in-half {
  opacity: 0;
  opacity: 1 \9
; /*just in case ie*/
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}