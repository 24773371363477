﻿.editorial-block {
    .intro {
        text-align: center;

        h2 {
            font-size: 28px;
            margin: 60px 0 0 !important;
            font-family: Gilroy-SemiBold, sans-serif, sans-serif;
            
            @include breakpoint(tablet-portrait) {
                font-size: 2.25rem;
            }
            @include breakpoint(desktop) {
                font-size: 2.75rem;
            }
        }
    }
}