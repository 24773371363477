
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.nav-link {
    &.active {
        background: inherit;
        border: 0;

        &:after {
            color: $ocean;
            top: calc(50% - 10px);
        }
    }

    &:after {
        font-weight: 700;
        color: $silver;
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        display: inline-block;
        content: "\f111";
        font-size: .875em;
        left: 10px;
        top: calc(50% - 11px);
    }

    &.completed:not(.active) {
        &:after {
            color: $ocean;
            content: "\f058";
        }
    }


    .card {
        border-radius: 8px;

        &.highlighted {
            background: $beach;
        }
    }

    .card-body {
        padding: 16px;

        @include attention() {
            cursor: pointer;
        }

        input[type=radio] {
            width: 24px;
            height: 24px;
        }

        i {
            font-size: 19px;
        }
    }

    .card-label {
        position: absolute;
        top: -15px;
        left: 25px;
        padding: 2px 8px;
        border-radius: 6px;
        background-color: $violet;
        color: $white;
    }

    .checkbox-container {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        /* Adjust as needed */
        cursor: pointer;
        user-select: none;
    }

    /* Hide the actual checkbox input */
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Style the custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        border: 1px solid $gray;
    }

    /* Style the checkmark icon (FontAwesome) */
    .checkmark i {
        display: none;
        /* Hide by default */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #333;
    }

    /* Show the checkmark icon when the checkbox is checked */
    .checkbox-container input:checked+.checkmark {
        background-color: $dark-ocean;
        border-color: $dark-ocean;

        i {
            display: block;
            font-size: 10px;
            color: $white;
        }
    }
}

@include breakpoint(tablet-portrait) {

    .checkout-page-container {
        background-color: $light-smoke;
    }

    .nav-tabs {
        flex-grow: 1;
    }

    .nav-item {
        flex-grow: 1;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

        .nav-link {
            flex-grow: 1;
            background-color: $white;

            p {
                color: $emperor;
            }

            &:after {
                color: $gallery;
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                display: inline-block;
                font-weight: 700;
                content: "\f111";
                font-size: 16px;
                left: 25px;
                top: calc(50% - 11px);
            }

            &.active {
                background-color: $deep-ocean;

                p {
                    color: $ocean-foam;
                }

                &:after {
                    color: $summer-sky;
                    content: "\f192";
                }
            }

            &.completed:not(.active) {
                background-color: $dark-ocean;

                p {
                    color: $ocean-foam;
                }

                &:after {
                    color: $summer-sky;
                    content: "\f058";
                }
            }
        }

        &:first-child {
            .nav-link:after {
                left: 15px;
            }
        }
    }

    .arrow__container {
        --d: 1rem;
        /* arrow depth */
        --gap: 0.4rem;
        /* arrow thickness, gap */
        margin-right: var(--d);
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.07));
    }

    .arrow__item {
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-right: calc(var(--d) * -1 + var(--gap));
        clip-path: polygon(0% 0%, calc(100% - var(--d)) 0%, 100% 50%, calc(100% - var(--d)) 100%, 0% 100%, var(--d) 50%);

        .arrow__content {
            padding-left: 35px;
        }

        &:first-child {
            clip-path: polygon(0% 0%, calc(100% - var(--d)) 0%, 100% 50%, calc(100% - var(--d)) 100%, 0% 100%);

            .arrow__content {
                padding-left: 25px;
            }
        }
    }

}

@include breakpoint(max-tablet-portrait) {
    .checkout__mobile-btn {
        position: absolute;
        left: 0;
    }
}
