﻿.dashboard__container {
  .site-content {
    .credits-container {
      border: solid 1px #EAEAEA;
      border-radius: 6px;
      list-style-type: none;
      padding: 0;

      li:first-child {
        border: none;
      }

      li {
        border-top: solid 1px #EAEAEA;
        p {
          margin: 0.5rem 0;
        }
      }

      .dateofinquiry {
        color: $gray;
        font-size: 12px;
      }

      .nameinquirer {
        color: $night-rider;
        font-size: 16px;
      }

      .creditlabel {
        color: $ocean;

        &.bold {
          font-weight: 700;
        }
      }

      .creditamount {
        color: $night-rider;
        font-size: 14px;
      }

      .row {
        padding-right: 10px;

        i {
          cursor: pointer;
        }
      }
    }
  }
}
