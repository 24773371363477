﻿.form-group {
  .form-control {
    &.is-valid:disabled {
      background-image: none;
    }
    &.is-valid {
      border-color: $feedback-success-400;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 363.025 363.024' style='enable-background:new 0 0 363.025 363.024;' xml:space='preserve'><g><g><g><path style='fill:%2328a745; stroke:%23ffffff; stroke-width:5;' d='M181.512,363.024C81.43,363.024,0,281.601,0,181.513C0,81.424,81.43,0,181.512,0 c100.083,0,181.513,81.424,181.513,181.513C363.025,281.601,281.595,363.024,181.512,363.024z M181.512,11.71 C87.88,11.71,11.71,87.886,11.71,181.513s76.17,169.802,169.802,169.802c93.633,0,169.803-76.175,169.803-169.802 S275.145,11.71,181.512,11.71z'/></g></g><g><polygon style='fill:%2328a745;' points='147.957,258.935 83.068,194.046 91.348,185.767 147.957,242.375 271.171,119.166 279.451,127.445 '/></g></g></svg>");

      &:focus {
        border-color: $feedback-success-400;
      }
    }
    &.is-invalid {
      border-color: $feedback-error;
      
      &:focus {
        border-color: $feedback-error;
      }
      @include placeholder {
        color: rgba(#ae2727, 0.8);
      }
    }
    &.is-valid,
    &.is-invalid {
      box-shadow: none;
      border-width: 1px;
    }
  }
  small.is-invalid {
    color: $feedback-error;
  }
  .checkbox-error-placement {
    display: block;
  }
}