﻿@import "../abstracts/_variables.scss";
.enento__toggle-input {
    height: 0;
    width: 0;
    visibility: hidden;
}

.enento__toggle {
    cursor: pointer;
    width: 36px;
    height: 24px;
    background: grey;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    @include breakpoint(max-tablet-portrait) {
        margin-right: 2rem;
    }
    i {
        display: none;
        position: absolute;
        color: $feedback-success;
        right: 4px;
        top: calc(50% - 8px);
        z-index: 19;
        @include breakpoint(max-phone) {
            right: 5px;
            top: calc(50% - 6px);
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: -1px;
        width: 24px;
        height: 24px;
        border: 1px solid grey;
        background: $white;
        border-radius: 90px;
        transition: 0.3s;
    }
    &:active:after {
        width: 28px;
    }
}

.enento__toggle-input:checked + .enento__toggle {
    background: $feedback-success-400;
    &:after {
        border: 1px solid $feedback-success;
    }
    i {
        display: block;
    }
}

.enento__toggle-input:checked + .enento__toggle:after {
    left: calc(100% - 0px);
    transform: translateX(-100%);
}
.enento__toggle-on, .enento__toggle-off {
    position: absolute;
    color: $gray-700;
    display: none;
    pointer-events: none;
    right: -30px;
} 
.enento__toggle-on {
    display: none;
}
.enento__toggle-disabled {
    background: $dark;
}
.enento__toggle-input:checked + .enento__toggle .enento__toggle-on {
    display: block;
}
.enento__toggle-off {
    display: block;
}
.enento__toggle-input:checked + .enento__toggle .enento__toggle-off {
    display: none;
}
