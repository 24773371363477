
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.nds-button-container {
  margin: 1.5rem 0 1rem 0;
  padding-bottom: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

@include breakpoint(max-phone) {
  .nds-button-container {
    flex-direction: column;
  }
}

.cookie-consent-link-button {
  background: none;
  color: $dark-ocean;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {
    text-decoration: underline;
  }
}
