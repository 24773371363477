//If this accordion is a child of dashboard__table there's certain styling for that particular one in _tables.scss
.block__enento__accordion {
  .panel-group {
    border-bottom: 1px solid $silver;

    .panel-heading {
      position: relative;

      a {
        color: $night-rider !important;
        text-decoration: none;

        &:after {
          position: absolute;
          top: calc(50% - 12px);
          right: 16px;
          font-family: "Font Awesome 5 Pro";
          color: $dark-ocean;
          font-style: normal;
          content: "\f078";
        }
      }

      h5 {
        border-top: 1px solid $silver;
      }

    }

    .panel-heading.not-read {
      h5, h6 {
        background-color: $rodeo-dust;
      }
    }

    .panel-heading:not(.not-read) {
      a:hover {
        h4, h5, h6 {
          background-color: $light-smoke;
        }
      }


      a:not(.collapsed) {
        > p {
          background-color: $light-smoke;
        }

        h5 {
          background-color: $light-smoke;
          border-bottom: 1px solid $silver;
        }

        h6 {
          background-color: $light-smoke;
        }

        &:after {
          content: "\f077";
        }
      }
    }

    .panel-body {
      a {
        color: $dark-ocean;

        @include attention() {
          color: $deep-ocean;
        }
      }
    }
  }

  .panel-group-empty {
    .panel-heading {
      position: relative;
      color: $night-rider !important;
      text-decoration: none;
      position: absolute;
      top: calc(50% - 12px);
      right: 16px;
      font-family: "Font Awesome 5 Pro";
      color: $dark-ocean;
      font-style: normal;
      content: "\f078";

      h5 {
        border-top: 1px solid $silver;
      }

      h5, h6 {
        background-color: $rodeo-dust;
      }
    }
  }

  .block__enento__accordion-body {
    a {
      position: relative;
      text-decoration: underline;
      padding-left: 20px;

      &:before {
        font-weight: 400;
      }

      &.accordion-pdf:before {
        position: absolute;
        top: 0;
        left: 0;
        font-family: "Font Awesome 5 Pro";
        color: $dark-ocean;
        font-style: normal;
        content: "\f1c1";
      }

      &.accordion-link:before {
        position: absolute;
        top: 0;
        left: 0;
        font-family: "Font Awesome 5 Pro";
        color: $dark-ocean;
        font-style: normal;
        content: "\f35d";
      }
    }
  }

  &.block__enento__accordion-footer {
    .panel-group {
      border-color: $gray;
      &:first-child {
        border-top: 1px solid $gray;
      }
      .collapse {
        &:first-child {
          padding-top: 16px;
        }
        &:last-child {
          padding-bottom: 16px;
        }
      }
      .panel-heading {
        a {
          color: $white !important;

          &:after {
            color: $white;
          }
        }

        a:hover {
          h4 {
            background-color: $emperor;
          }
        }

        a:not(.collapsed) {
          margin-bottom: 16px;
          &:after {
            content: "\f077";
          }

          h4 {
            background-color: $emperor;
          }
        }
      }
    }

    .block__enento__accordion-body {
      a {
        text-decoration: none;
        padding-left: 8px;
      }
    }
  }
}

.block__enento__accordion__card-wrapper {
  border: solid 1px $gallery;
  border-radius: 6px;

  .block__enento__accordion__card {
    border-bottom: 1px solid $gallery;
    
    .panel-heading {
      position: relative;

      a {
        color: $night-rider !important;
        text-decoration: none;

        &:hover {
          background-color: $light-smoke;
        }

        &:after {
          position: absolute;
          top: calc(50% - 12px);
          right: 16px;
          font-family: "Font Awesome 5 Pro";
          color: $dark-ocean;
          font-style: normal;
          content: "\f078";
        }
      }

      a:not(.collapsed) {
        border-bottom: none;
        background-color: $light-smoke;

        &:after {
          content: "\f077";
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
