
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.personal-information-form {
  .form-group {
    label {
      color: $night-rider;
      font-size: 14px;
      font-family: Gilroy-Bold;
    }
  }
}

.submit-button {
  .submit-button-content {
    display: inline-flex;
    align-items: center;
  }
}

.alert {
  margin-top: 5px;
  padding-left: 65px;

  .alert-icon {
    left: 15px;
    top: calc(50% - 20px);
  }
}

.checkout-productinformation-wrapper {
  img {
    position: absolute;
    top: 6px;
    right: 24px;
    height: 110px;
  }

  .checkout-accordion-link {
    &:after {
      font-family: "Font Awesome 5 Pro";
      font-size: 12px;
      content: '\f077';
      color: $dark-ocean;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.collapsed {
      &:after {
        content: '\f078';
      }
    }
  }
}

@include breakpoint(tablet-landscape) {
  .checkout-productinformation-wrapper {
    img {
      height: auto;
      top: -100px;
      right: 40px;
    }
  }
}
