﻿.hero-section {
  background-color: #e9e9e9;

  &.hero-section__background {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .hero-section__container {
      .hero-section__text {

        h2,
        p {
          color: $white;
        }

        &.plate {

          h2,
          p {
            color: $black;
          }
        }
      }
    }
  }

  &.hero-section__gradient {
    background: #666666;
    background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(233, 233, 233, .4) 10%, rgba(214, 214, 214, 0) 40%), -moz-linear-gradient(top, rgba(153, 153, 153, .25) 0%, rgba(102, 102, 102, .4) 100%), -moz-linear-gradient(45deg, $white 0%, #666666 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(233, 233, 233, .4) 10%, rgba(214, 214, 214, 0) 40%), -webkit-linear-gradient(top, rgba(153, 153, 153, .25) 0%, rgba(102, 102, 102, .4) 100%), -webkit-linear-gradient(45deg, $white 0%, #666666 100%);
    background: -o-radial-gradient(0% 100%, ellipse cover, rgba(233, 233, 233, .4) 10%, rgba(214, 214, 214, 0) 40%), -o-linear-gradient(top, rgba(153, 153, 153, .25) 0%, rgba(102, 102, 102, .4) 100%), -o-linear-gradient(45deg, $white 0%, #666666 100%);
    background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(233, 233, 233, .4) 10%, rgba(214, 214, 214, 0) 40%), -ms-linear-gradient(top, rgba(153, 153, 153, .25) 0%, rgba(102, 102, 102, .4) 100%), -ms-linear-gradient(45deg, $white 0%, #666666 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(233, 233, 233, .4) 10%, rgba(214, 214, 214, 0) 40%), linear-gradient(to bottom, rgba(153, 153, 153, .25) 0%, rgba(102, 102, 102, .4) 100%), linear-gradient(180deg, $white 0%, #666666 100%);

    .hero-section__container {
      .hero-section__text {
        color: $white;
      }
    }
  }

  .hero-section__container {
    padding: 20px;
    min-height: 100%;

    &.text-center {
      .hero-section__text {

        h2,
        p {
          text-align: center;
        }
      }
    }

    .hero-section__text {
      width: 100%;
      color: black;
      padding: 20px;
      min-height: 100px;
      min-width: 100px;

      > hr {
        max-width: 150px;
        margin-left: 0;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        border-radius: 2px;
        border-top: 0;
        border-bottom: 4px solid $ocean;
      }

      .text-container {
        &.text-right {

          h2,
          h3,
          h4,
          p {
            text-align: right;
          }
        }

        .buttonblock {
          display: inline-block;
        }
      }

      &.vertical-align-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        padding-bottom: 0;
      }

      &.plate {
        background-color: $white;
        border-radius: 5px;
        color: black;
        margin-left: 20px;
        margin-bottom: 20px;
        padding-bottom: 1.5rem;

        h2,
        p {
          text-shadow: none;
        }
      }

      h2,
      p {
        text-align: left;
        text-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
      }

      h2 {
        font-size: 2.25rem;
        line-height: 2.25rem;
        margin: 1rem 0;
      }

      p {
        line-height: 1.1rem;
      }

      p.preamble {
        font-family: Gilroy-SemiBold;
        font-size: 1.25rem;
        line-height: 1.75rem;

        span {
          font-family: Gilroy-SemiBold;
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
      }

      p.preamble-sm {
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        line-height: 1.1rem;

        span {
          font-family: Gilroy-SemiBold;
          font-size: 14px;
          line-height: 1.1rem;
        }
      }

      p.btn {
        a {
          text-decoration: none;
        }
      }

      &.vertical-align-middle {
        margin-left: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }  
  }

  @include breakpoint(max-tablet-portrait) {
    .hero-section__container {
      .hero-section__text {
        &.plate {
          padding-bottom: 1.25rem;
        }

        &.vertical-align-bottom {
          padding-bottom: 1rem;
        }

        h2 {
          font-size: 2rem;
          line-height: 2rem;
          margin: 0.5rem 0;
        }

        p {
          font-size: 1.1rem;
          line-height: 1.5rem;

          span {
            font-size: 1.1rem;
            line-height: 1.5rem;
          }
        }

        .btn {
          margin: 1rem 1rem 0.5rem 0;
        }
      }
    }
  }

  @media screen and (max-height: 768px) {
    h1 {
      font-size: 2.125em;
      line-height: 2.5rem;
    }

    h2 {
      font-size: 1.75em;
      line-height: 2rem;
    }

    h3 {
      font-size: 1.25em;
      line-height: 1.75rem;
    }

    h4 {
      font-size: 1em;
      line-height: 1.5rem;
    }
  }
}
