﻿.buttonblock {
  .btn {
    margin: 1.5rem 1rem 1rem 0;
    width: 100%;
    @include breakpoint(max-tablet-portrait) {
      width: 100%;
    }
    &.w-max-285 {
      max-width: 285px;
    }
  }

  .svg-image {
    min-width: 180px;
    height: 180px;
    @include breakpoint(max-phone) {
      height: 140px;
      min-width: 140px;
    }
  }

  .img__container-text {
    width: 100%;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      line-height: 3.5rem;
    }
    @include breakpoint(max-phone) {
      h1 {
        line-height: 2rem;
      }
      h5, p {
        font-size: 12px;
      }
      p {
        line-height: 18px;
      }
    }
  }
}