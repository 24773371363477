﻿.dashboard__container {
    .site-content {
        &.myucscorepage {
            .dashboard__card {
                @include breakpoint(max-phone) {
                    padding: 1rem;
                    margin:0;
                }

                border-radius: 6px;
                margin-left: -8px;
                margin-right: -8px;
            }

            .question-uc-score-wrapper {
                position: relative;
                padding-right: 45px;

                i {
                    position: absolute;
                    right: 16px;
                    top: calc(50% - 7px);
                }
            }

            .h2-bold {
                font-weight: 700;
            }

            background: linear-gradient( 180deg, #004755 709px, #E5E5E5 20px, #E5E5E5 40px);

            @include breakpoint(max-phone) {
                background: linear-gradient( 180deg, #004755 589px, #E5E5E5 20px, #E5E5E5 40px);
            }

            .risk-score-container {
                padding-left: 1.5rem;
                padding-right: 1.5em;
                max-width: 445px;
                margin: 0 auto;
                display: block;

                .slider-pin {
                    position: relative;
                    height: 35px;
                    width: 100%;

                    .pin-container {
                        color: #80BCC8;
                        height: 35px;
                        position: absolute;
                        padding-right: 15px;

                        span {
                            position: absolute;
                            top: 0;
                        }

                        i {
                            position: absolute;
                            bottom: 0;
                            left: 5px;
                        }
                    }
                }

                .risk-uc-score-slider {
                    background: linear-gradient(90deg, #DE4953 4.56%, #F39200 23.45%, #9BCDA0 53.77%, #00A04B 100%);
                    border-radius: 29px;
                    /*transform: matrix(-1, 0, 0, 1, 0, 0);*/
                    height: 7px;
                    margin-bottom: 0.5rem;
                    width: 100%;

                    .slider-fill {
                        float: right;
                        background-color: white;
                        width: 0;
                        height: 100%;
                        border-radius: 29px;
                    }
                }

                .risk-grade-label {
                    float: right;
                    text-align: center;
                    /*width: 71px;*/
                    padding-left: 4px;
                    padding-right: 4px;
                    position: relative;
                    color: #00A04B;
                    border: 2px solid #00A04B;
                    font-weight: 600;
                    box-sizing: border-box;
                    border-radius: 6px;

                    &.very-good {
                        color: #00A04B;
                        border: 1px solid #00A04B;
                    }

                    &.good {
                        color: #9BCDA0;
                        border: 1px solid #9BCDA0;
                    }

                    &.less-good {
                        color: #F39200;
                        border: 1px solid #F39200;
                    }

                    &.weak {
                        color: #DE4953;
                        border: 1px solid #DE4953;
                    }
                }

                .risk-grade-intervall {
                    color: #80BCC8;
                    float: right;
                    position: relative;
                }
            }

            .uc-score-graph-container {
                .highcharts-data-label {
                    font-weight: 500;
                    text-shadow: #333 1px 1px;
                }

                .uc-score-graph {
                    margin: 0 auto;
                    /*width: 635px;*/

                    @include breakpoint(max-phone) {
                        height: 300px;
                        width: 100%;
                    }
                }

                @include breakpoint(max-phone) {
                    .uc-score-graph.first-row {
                        margin-top: 0;
                    }
                }
                /*height: 450px;*/

                .highcharts-background {
                    fill: #004755 !important;
                }

                .highcharts-axis-line, .highcharts-tick {
                    stroke: transparent;
                    stroke-width: 0;
                }

                .highcharts-axis-labels {
                    fill: #80BCC8 !important;

                    text {
                        fill: #80BCC8 !important;
                    }

                    text:last-child {
                        fill: #F39200 !important;
                    }
                }

                .hc-plot-up {
                    stroke: #008EAA !important;
                    stroke-width: 2px;
                }

                .series-black .highcharts-point {
                    fill: unset;
                    stroke: unset;
                }
                .series-weak .highcharts-point {
                    fill: #DE4953 !important;
                    stroke: #DE4953;
                }

                .series-less-good .highcharts-point {
                    fill: #F39200 !important;
                    stroke: #F39200;
                }

                .series-good .highcharts-point {
                    fill: #9BCDA0 !important;
                    stroke: #9BCDA0;
                }

                .series-very-good .highcharts-point {
                    fill: #00A04B !important;
                    stroke: #00A04B;
                }

                .series-excellent .highcharts-point {
                    fill: #00A04B !important;
                    stroke: #00A04B;
                }

                .highcharts-series.highcharts-series-0.highcharts-line-series.highcharts-color-0.series-line {
                    stroke-opacity: 0.25;
                }
            }

            .highcharts-label-box {
                /*background-color: unset;*/
                fill: none !important;
            }

            .usergraph__hc-nav {
                color: $white;

                @include breakpoint(max-phone) {
                    margin-bottom: 1rem;
                    margin-top: 0.5rem;
                }

                .usergraph__hc-current {
                    font-size: 0.75rem;
                }


                .usergraph__hc-navnext, .usergraph__hc-navprev {
                    font-size: 0.75rem;
                    background: unset;
                    color: $light-smoke;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
