
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.checkout-select-radio-input {
  accent-color: $dark-ocean;
}

.card {
  border-radius: 8px;

  &.highlighted {
    background: $beach;
  }

  .card-body {
    padding: 16px;

    @include attention() {
      cursor: pointer;
    }

    input[type=radio] {
      width: 24px;
      height: 24px;
    }

    i {
      font-size: 19px;
    }
  }

  .card-label {
    position: absolute;
    top: -15px;
    left: 25px;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: $violet;
    color: $white;
  }
}
