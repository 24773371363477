﻿#menu-layer {
  .container {
    position: relative;
    z-index: 2;
  }
}

.site-menu-wrapper {
  left: 0;
  right: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 62px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 0.17s ease-out;

  @include breakpoint(tablet-portrait) {
    padding-top: 40px;
    padding-bottom: 40px;
    top: 70px;
  }

  @include breakpoint(desktop) {
    padding-top: 40px;
    padding-bottom: 40px;
    top: 100px;
  }
}

.site-menu {
  z-index: 2;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
}

.site-menu--usermenu {
  margin-bottom: 2rem;
}

.site-menu__item {
  list-style: none;
  border-bottom: 1px solid rgba($white, 0.2);
  text-align: left;
  margin: 0;

  &.parent {

    &.active {
        .site-menu__child {
            &.open{display:block;}
        }
    }
    .site-menu__child {

        &.closed{
            display:none;
        }
      .site-menu__item:last-child {
        border-bottom: 0;
      }

      .parent {
        .site-menu__item-link {
          font-size: 1.125rem;
        }

        .site-menu__child {
          .site-menu__item-link {
            font-size: 1rem;
          }
        }
      }
    }

    > .site-menu__item-link {
      font-size: 1.5rem;
    }
  }

  &.active > a {
    color: $tahiti-gold;
  }
}

.site-menu__item-link,
.site-menu__item-right-link {
  display: inline-block;
  color: $silver;
  padding: 10px 0;
  font-size: 1.125rem;
  line-height: 32px;

  &.open {
    color: $tahiti-gold;
  }

  @include attention() {
    color: $tahiti-gold;
    text-decoration: none;
  }
}

.site-menu__item-right-link {
  font-size: 1.5rem;
}

.site-menu__item-toggle {
  color: $nobel;
  line-height: 34px;
  margin-top: 8px;
  cursor: pointer;
  margin-left: 15px;
}

.site-menu__child {
  @extend .site-menu;
  margin: 10px 0 20px 15px;
  padding: 0;
}

.site-menu__child-item {
  @extend .site-menu__item;
  margin: 0 0 0 15px;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
    border: 0;
  }
}


.site-menu__child-item-link {
  @extend .site-menu__item-link;
  font-size: 1rem;
  line-height: 28px;
}

.site-menu__child-item-toggle {
  @extend .site-menu__item-toggle;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-top: 6px;
}

.site-menu__grandchild {
  @extend .site-menu__child;
  margin: 0 0 0 15px;
  padding: 0;
}

.site-menu__grandchild-item {
  @extend .site-menu__child-item;
  margin: 0 0 0 15px;
  padding: 0;
}


.site-menu__grandchild-item-link {
  @extend .site-menu__child-item-link;
  font-size: 1rem;
  line-height: 28px;
}

.site-menu--secondary {
  @extend .site-menu;
  margin-top: 40px;
}

.site-menu--secodary__item {
  @extend .site-menu__item;
}

.site-menu--secondary__item-link {
  @extend .site-menu__item-link;
  font-size: 1em;
}

.site-menu__lang {
  z-index: 2;
  position: relative;
  max-width: 600px;
  margin: 0 auto 1.5rem;
  padding: 0 15px;

  @include breakpoint(tablet-portrait) {
    display: none;
  }
}

.site-menu__lang-list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include clearfix;

  &:before {
    content: "\f0ac";
    font-family: "Font Awesome 5 Pro";
    position: relative;
    top: 1px;
    float: left;
    color: $white;
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }
}

.site-menu__lang-item {
  display: block;
  float: left;
  margin: 0.3125rem 0 0 0.5rem;
  padding: 0 0 0 0.5rem;
  border-left: 1px solid $night-rider;
  font-size: 1rem;
  text-transform: capitalize;
  position: relative;
  top: 1px;

  &:first-child {
    margin: 0.3125rem 0 0 0;
    padding: 0;
    border-left: 0;
  }

  a {
    color: $gray;
  }
}

.site-menu__lang-item--active {
  font-family: Gilroy-SemiBold, sans-serif;
  top: 0;
  color: $white;
}
