
.button-spacing-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.second-position-sticky {
  top: 58px;
  position: sticky;
  transition: top 0.25s ease-in-out;
  width: 100%;
  z-index: 1;
}
