﻿.editor {

    h1,
    h2 {
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $tundora;
        margin: 0 0 1rem;

        @include breakpoint(tablet-portrait) {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }

        @include breakpoint(max-tablet-portrait) {
            margin-bottom: 0.5rem;
        }
    }

    h3 {
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $tundora;
        margin: 2rem 0 1rem;

        @include breakpoint(max-tablet-portrait) {
            margin-bottom: 0.5rem;
        }
    }

    h4 {
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 1.25rem;
        line-height: 1.25rem;
        color: $tundora;
        margin: 0 0 0.625rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.375rem;
        color: $tundora;
        margin: 0 0 1rem;

        &.preamble {
            @include breakpoint(tablet-portrait) {
                font-size: 1.25rem;
                line-height: 1.75rem;
            }
        }
    }

    a {
        color: $tahiti-gold;
        font-family: Gilroy-SemiBold, sans-serif;

        @include attention() {
            color: $christine;
        }
    }
}
