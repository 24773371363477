
@import "../../../styles/abstracts/_breakpoints.scss";

.phone-large {
  font-family: Gilroy-Bold;
  font-size: 2em;
  line-height: 2.5rem;

  @include breakpoint(max-phone) {
    font-size: 1.75em;
    line-height: 2rem;
  }
}

.block__enento__accordion .panel-group {
  border-bottom: none;

  .panel-heading a {

    &.collapsed:hover h5 {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:after {
      right: 0;
    }
  }
}
