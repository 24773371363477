<template>
    <div class="mt-3 mb-4">
        <NdsHeading :title="chartHeader" type="h4" tight>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
                @click="propertyCreditDevelopmentQuestionModal.openModal" aria-label="Förklaring Kreditutveckling" />
        </NdsHeading>
        <div class="nds-credit-chart-container">
            <NdsCreditPlotChart title="" emptyTitle="Ingen kreditutveckling registrerad" :chartData="chartData" />
        </div>
    </div>

    <NdsHeading :title="headline" type="h4" tight>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
            @click="propertyCreditQuestionModal.openModal" aria-label="Förklaring Fastighetslån" />
    </NdsHeading>

    <div class="d-flex justify-content-between mb-3">
        <div v-if="paginatedPropertyCredits.length > 0" class="d-flex mr-2 credit-summary-wrapper">
            <p class="d-flex flex-wrap m-0">
                <span class="text-nowrap mr-1">{{ debtLabel }}</span>
                <span class="color-ocean">{{ propertyCreditSummary }}</span>
            </p>
        </div>

        <div v-if="hasTerminatedPropertyCredits" class="ml-auto">
            <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
                variant="secondary" @click="showTerminated = !showTerminated">
                {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
            </NdsButton>
        </div>
    </div>

    <div v-if="paginatedPropertyCredits.length > 0">
        <NdsAccordion v-model="activeAccordionId" :accordions="paginatedPropertyCredits">
            <template v-for="(credit, index) in paginatedPropertyCredits" #[credit.id] :key="index">
                <CreditDetails :creditData="credit" />
            </template>
        </NdsAccordion>
    </div>
    <NdsCard v-else tight outlined>
        <ZeroState icon="far fa-sack-dollar" :text="zeroStateText" />
    </NdsCard>
    <div v-if="totalPages > 1" class="mt-4">
        <NdsPagination :length="totalPages" v-model="currentPage" />
    </div>
    <NdsFooter class="mt-3 p-0" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige"
        tight />
    <NdsConfirmModal v-bind="propertyCreditDevelopmentQuestionModal.props"
        v-model="propertyCreditDevelopmentQuestionModal.isOpen.value"
        @onConfirm="propertyCreditDevelopmentQuestionModal.closeModal"
        @onCancel="propertyCreditDevelopmentQuestionModal.closeModal"
        @onDismiss="propertyCreditDevelopmentQuestionModal.dismissModal" />
    <NdsConfirmModal v-bind="propertyCreditQuestionModal.props" v-model="propertyCreditQuestionModal.isOpen.value"
        @onConfirm="propertyCreditQuestionModal.closeModal" @onCancel="propertyCreditQuestionModal.closeModal"
        @onDismiss="propertyCreditQuestionModal.dismissModal" />
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { formatToSek } from '../_helpers.js';
import { NdsCard, NdsHeading, NdsIconButton, NdsButton, NdsConfirmModal, useConfirmModal, NdsCreditPlotChart, NdsAccordion, NdsPagination, NdsFooter, usePagination } from '@nds/vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import { useEbbot } from '../utils/ebbotUtils.js';
import { creditTypes, getTotalCreditDebt, formatCredits } from '../utils/creditUtils.js'
import CreditDetails from './CreditDetails.vue';

const props = defineProps({
    model: Object,
    report: Object,
});

const modelItem = props?.model?.creditContentArea?.expandedValue?.[3];
const chartHeader = computed(() => modelItem?.chartHeader.value);
const headline = computed(() => modelItem?.headline.value);
const debtLabel = computed(() => modelItem?.debtLabel.value);
const primaryModalHelpText = computed(() => modelItem?.primaryModalHelpText.value);
const secondaryModalHelpText = computed(() => modelItem?.secondaryModalHelpText.value);

const showTerminated = ref(true);
const hasTerminatedPropertyCredits = ref(false);

const propertyCreditTotal = getTotalCreditDebt(props.report, creditTypes['propertyCredit']);
const propertyCreditSummary = formatToSek(propertyCreditTotal);

const formattedPropertyCredits = computed(() => formatCredits(creditTypes['propertyCredit'], "propertyCredit", hasTerminatedPropertyCredits, props.report));
const filteredFormattedPropertyCredits = computed(() => (formattedPropertyCredits.value.filter(propertyCredit => (showTerminated.value ? true : !propertyCredit.toDate))));

const zeroStateText = computed(() => hasTerminatedPropertyCredits.value ? "Inga aktiva fastighetslån registrerade" : "Inga fastighetslån registrerade");

const { showEbbot, hideEbbot } = useEbbot();

const propertyCreditDevelopmentQuestionModal = useConfirmModal({
    title: 'Kreditutveckling',
    variant: 'primary',
    prefix: { prefix: "far", iconName: "question-circle" },
    confirmBtnLabel: "Stäng",
    body: {
        text: primaryModalHelpText,
        isRichText: true,
    },
    onOpenCallback: hideEbbot,
    onDismissCallback: showEbbot,
    onCloseCallback: showEbbot,
});

const propertyCreditQuestionModal = useConfirmModal({
    title: 'Fastighetslån',
    variant: 'primary',
    prefix: { prefix: "far", iconName: "question-circle" },
    confirmBtnLabel: "Stäng",
    body: {
        text: secondaryModalHelpText,
        isRichText: true,
    },
    onOpenCallback: hideEbbot,
    onDismissCallback: showEbbot,
    onCloseCallback: showEbbot,
});

const chartData = computed(() => ({
    snapshotLabel: 'Nuläge',
    hasSnapshot: true,
    label: 'Utnyttjad kredit',
    entries: props.report.propertyChartData.entries,
}));

const {
    currentPage,
    totalPages,
    paginatedItems: paginatedPropertyCredits,
} = usePagination(filteredFormattedPropertyCredits);

const activeAccordionId = ref([]);

watch(currentPage, () => {
    activeAccordionId.value = [];
});
</script>
<style scoped>
.nds-credit-chart-container {
    margin-top: 16px;
    width: 100%;
    height: 338px;
}

.credit-summary-wrapper {
    flex: 1;
}
</style>