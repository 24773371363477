﻿.dashboard__container {
    .dashboard__wrapper {
        .chart-tooltip {
            background-color: #F7F7F7;
            /*width: 219px;
            height: 83px;*/
            padding: 5px;
            padding-bottom: 0;
            border-radius: 6px;
            text-align: center;
            /*box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);*/
            ul {
                padding: 0;
                list-style-type: none;
            }

            .tooltip-header {
                font-size: 16px;
                color: #1D1C1D;
                text-transform: capitalize;
            }

            .tooltip-label {
                font-size: 14px;
                color: #484848;

                &:first-child {
                    margin-top: 8px;
                }

                &:last-child {
                    color: #036D82;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }

        .highcharts-container {
            font-family: "Gilroy-Regular";
        }

        .highcharts-data-label text {
            fill: #000;
        }

        .highcharts-color-0 {
            fill: #F5EAE3;
            stroke: #F5EAE3;
        }

        .highcharts-label-box {
            stroke: #ccc;
            background-color: $white;
            fill: $white !important;
        }

        .highcharts-tooltip-box {
            fill-opacity: 1;
            fill: #F7F7F7 !important;
            stroke-width: 0;
        }

        .highcharts-color-1 {
            fill: $deep-ocean !important;
            stroke: $deep-ocean;
        }

        .highcharts-area {
            fill: #F5EAE3;
        }

        .highcharts-data-label text {
            font-size: 0.875rem;
            letter-spacing: 0.125rem;
            fill: $night-rider;
            font-family: Gilroy-Regular, sans-serif;
        }

        .highcharts-axis-labels {
            fill: $gray;
            cursor: default;
            font-size: 0.75rem;
            text-transform: uppercase;
            font-family: Gilroy-Regular, sans-serif;
        }

        @media (max-width: 767px) {
            .highcharts-yaxis,
            .highcharts-yaxis-labels {
                display: unset;
            }
        }

        .highcharts-axis-labels text:last-child {
            fill: $night-rider;
        }


        .chart-border {
            border: solid 1px #EAEAEA;
            padding: 30px 0 5px 0;
            border-radius: 6px;
            /*margin-top: 20px;*/
            margin-bottom: 13px;

            @include breakpoint(max-phone) {
                padding: 0;
                border: none;
            }

            .legends {
                // Iphone S5 bugg
                @media (max-width: 320px) {
                    .col-xs-5 {
                        width: 40% !important;
                    }
                }

                .legend-square {
                    width: 25px;
                    height: 25px;
                    margin-right: 0.5rem;
                    background-color: #F5EAE3;
                    display: inline-block;

                    &.sand {
                        background-color: $deep-ocean;
                    }
                }

                .legend-label {
                    font-size: 1rem;
                    vertical-align: super;
                }
            }
        }
    }
}
