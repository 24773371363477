
@import "../../../styles/abstracts/_variables.scss";

.mobile-cards-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pdfModal {
  label {
    padding-left: 8px;
    color: #000;
  }

  .form-check-input {
    height: 24px;
    width: 24px;
    margin-top: 0;
    accent-color: $dark-ocean;

    &.disabled {
      opacity: 1 !important;
    }
  }
}
