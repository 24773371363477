.article-block {
  h2 {
    &.heading {
      font-size: 2.25rem;
    }
  }

  .image-left {
    float: left;
    margin-top: 10px;
  }

  .image-right {
    float: right;
    margin-top: 10px;
    margin-left: 15px;
  }

  .preamble,
  .description,
  .image-full-width {
    margin: 10px 0;
  }

  .preamble {
    font-size: 1rem;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    img {
      width: 34%;
      height: auto;
      margin-right: 15px;
    }
  }

  .description {
    font-size: 0.825rem;

    a {
      color: orange;
      text-decoration: underline;
      font-family: Gilroy-SemiBold;
      cursor: pointer;
    }
  }

  ol {
    list-style: none;
    margin: 10px 0;

    li {
      font-size: 1rem;

      &:before {
        vertical-align: middle;
        margin-left: -1.2rem;
        font-size: 1rem;
        color: gray;
      }
    }
  }

  ol {
    counter-reset: item;
    list-style-type: none;
    list-style-position: outside;
    padding-left: 25px;

    li {
      &:before {
        content: counter(item) "  ";
        counter-increment: item;
        padding-right: 0.5rem;
      }

      span {
        display: inline-flex;
      }
    }
  }

  ul:not(.check-marks) li {
    list-style: disc;
  }
}

.article-content-block {
  img {
    max-width: 100%;
  }
  
  @include breakpoint(desktop) {
    h2 {
      font-size: 2.125rem;

      &.heading {
        font-size: 2.5rem;
      }
    }

    h3 {
      font-size: 1.625rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1.25rem;
    }
  }

  @include breakpoint(max-desktop) {
    h2 {
      font-size: 1.875rem;

      &.heading {
        font-size: 2.25rem;
      }
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.376rem;
    }

    h5 {
      font-size: 1.125rem;
    }
  }

  @include breakpoint(max-tablet-portrait) {
    h2 {
      font-size: 1.5rem;

      &.heading {
        font-size: 2rem;
      }
    }

    h3 {
      font-size: 1.375rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
    }
  }
}