﻿// EXTENDING AND MODIFYING BOOTSTRAP
.table > tbody > tr > th {
  border-top: 0;
}

.text-black {
  color: $black;
}

figure {
  margin: 0;
}
input:disabled {
  color: $silver !important;
  background-color: $light-smoke !important;
  border-color: $gallery !important;
}

.btn-default {
  background: $tahiti-gold;
  border-color: $tahiti-gold;
  color: $white;
  font-size: 1rem;

  @include attention() {
    background: $christine;
    border-color: $christine;
    color: $white;
  }
}

.btn.disabled-custom {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

.btn:disabled {
  pointer-events: none;
}
.btn-phone-block {
  @include breakpoint(max-phone) {
    display: block;
  }
}

.pe-none {
  pointer-events: none;
}
.dropdown-menu > li > a {
  font-size: 1rem;
  padding: 0.875rem;
  display: block;
  @include attention() {
    text-decoration: none;
  }
}

.label-blue {
  background: $venice-blue;
}

.label-green {
  background: $green-haze;
}

.label-beige {
  background: $muddy-waters;
}

.label-red {
  background: $monza;
}

.label-yellow {
  background: $tahiti-gold;
}

input[type="radio"],
input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.nav-line {
  border-bottom: 2px solid $gallery;
  text-align: center;

  @include breakpoint(max-phone) {
    margin: 0 -15px;
  }

  > li {
    float: none;
    display: inline-block;
    margin: 0 0.5rem 0 0;

    @include breakpoint(max-phone) {
      margin: 0;
    }

    @include breakpoint(tablet-portrait) {
      margin: 0 1rem 0 0;
    }

    @include breakpoint(desktop) {
      margin: 0 1.5rem 0 0;
    }


    > a {
      padding: 0 0.25rem 0.5rem;
      position: relative;
      top: 2px;
      border-bottom: 2px solid $gallery;
      color: $dim-gray;

      @include breakpoint(max-phone) {
        font-size: 0.875rem;
      }

      @include breakpoint(tablet-portrait) {
        font-size: 1.125rem;
      }

      @include breakpoint(tablet-landscape) {
        font-size: 1.25rem;
      }

      @include breakpoint(desktop) {
        font-size: 1.5rem;
      }

      @include attention() {
        background: none;
        color: $night-rider;
      }
    }

    &.active {
      > a {
        color: $muddy-waters;
        border-bottom: 2px solid $muddy-waters;
      }
    }
  }
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.collapse.in {
  display: block;
}

.table {
  margin: 1rem 0;

  tr:hover {
    td {
      background: $dawn-pink;
      color: $black;
    }
  }
}

.table-responsive > .table {
  margin: 0;
}

//MODALS
.modal {
  &.fade.in {
    background: rgba(0, 0, 0, 0.95);
  }

  .modal-dialog {
    margin: 1.25rem auto;
    @include breakpoint(small-phone) {
      max-width: 346px;
    }
    @include breakpoint(tablet-portrait) {
      max-width: 450px;
    }
    @include breakpoint(large-desktop) {
      max-width: 528px;
    }
  }
  .modal-header, .modal-footer{
    border: none;
    padding: 1.5rem;
    button{
        min-width:100px;
    }
  }
  .modal-title {
    line-height: 1;
    i {
      font-size: 20px;
      margin-right: 0.5rem;
    }
  }
  i {
    font-size: 24px;
    color: $night-rider;
  }
  .modal-body {
    padding: 0 1.5rem;
  }
  .modal-footer > * {
    margin: 0;
  }
  .modal-footer button:last-child {
    margin-left: 1rem;
  }
}

.progress {
  border: 2px solid $white;
  -webkit-box-shadow: none;
  box-shadow: none;

  @include border-radius(10px);

  .progress-bar {
    box-shadow: none;
    -webkit-box-shadow: none;
    line-height: 1rem;
    font-size: 0.75rem;
    font-family: Gilroy-SemiBold, sans-serif;
  }

  .progress-bar-default {
    background-color: $gray;
  }

  .progress-bar-lessgood,
  .progress-bar-warning {
    background-color: $tahiti-gold;
  }

  .progress-bar-weak,
  .progress-bar-danger {
    background-color: $monza;
  }

  .progress-bar-good,
  .progress-bar-success {
    background-color: $vista-blue;
  }

  .progress-bar-excellent,
  .progress-bar-striped,
  .progress-bar-improved {
    background-color: $green-haze;
  }

  .progress-bar-verygood {
    background-color: #40b878;
  }

  .progress-bar-excellent-difference {
    background-color: #00863f;
  }

  .progress-bar-verygood-difference {
    background-color: #00a04b;
  }

  .progress-bar-good-difference {
    background-color: #3ab273;
  }

  .progress-bar-lessgood-difference {
    background-color: #ed680d;
  }

  .progress-bar-weak-difference {
    background-color: #b00814;
  }
}

.form-control {
  font-size: 1rem;
  height: auto;
  padding: 0.5rem;

  @include border-radius(6px);
}

.form-control:focus {
  border: 2px solid $ocean;
  border-radius: 6px;
  box-shadow: none;
}


.dropdown-menu.creditwatch {
  border: 0;
  background: $night-rider;
  margin-top: 6px;
  padding: 0.25rem 0;

  > li {
    margin: 2px 0;

    a {
      color: $green-haze;
      text-align: center;
      font-family: Gilroy-SemiBold, sans-serif;
      padding: 0.75rem 0.125rem;

      @include attention() {
        color: $white;
        background: $tundora;
      }
    }
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    color: $white;
    background: $tundora;
  }

  .arrow {
    position: absolute;
    top: -13px;
    right: 18px;
    width: 26px;
    height: 13px;
    overflow: hidden;

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid $night-rider;
      display: inline-block;
    }
  }
}

.dropdown-menu.light {
  background: $white;

  > li {
    margin: 2px 0;

    a {
      color: $tahiti-gold;
      text-align: center;
      font-family: Gilroy-SemiBold, sans-serif;
      padding: 0.75rem 0.125rem;

      @include attention() {
        color: $tahiti-gold;
        background: $half-colonial;
      }
    }
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    color: $tahiti-gold;
    background: $half-colonial;
  }

  .arrow {
    position: absolute;
    top: -13px;
    right: 18px;
    width: 26px;
    height: 13px;
    overflow: hidden;

    .arrow-up {
      border-bottom: 14px solid $white;
    }
  }
}

.dropdown-menu.dropdown-menu-center {
  width: 140px;
  min-width: 140px;
  left: 50%;
  margin-left: -70px;
  margin-top: 8px;

  .arrow {
    left: 50%;
    right: auto;
    margin-left: -13px;
  }
}

.accordion {
  color: $black;

  p {
    margin-bottom: 0;
  }

  .card-header {
    position: relative;

    a {
      color: $black;
    }

    a:after {
      position: absolute;
      right: 16px;
      width: 1.25rem;
      height: 1.25rem;
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      content: "\f078";
      color: $black;
    }

    a:not(.collapsed):after {
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      content: "\f077";
      color: $black;

    }
  }

  i, em {
    position: absolute;
    top: calc(50% - 8px);
    right: 16px;
  }
}

.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus,
.open > .btn-default.dropdown-toggle {
  background: $christine;
  border-color: $christine;
  color: $white;
}

.popover {
  border: 0;

  @include box-shadow(0, 5px, 25px, rgba(0, 0, 0, 0.25));
  @include border-radius(0.125rem);
}

.popover.top > .arrow {
  border-top-color: transparent;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.pull-right-not-xs, .pull-right-not-sm, .pull-right-not-md, .pull-right-not-lg {
  float: right;
}

.pull-left-not-xs, .pull-left-not-sm, .pull-left-not-md, .pull-left-not-lg {
  float: left;
}

@include breakpoint(max-tablet-portrait) {
  .pull-right-not-xs, .pull-left-not-xs {
    float: none;
  }

  .pull-right-xs {
    float: right;
  }

  .pull-left-xs {
    float: left;
  }
}

@include breakpoint(tablet-portrait) {
  @include breakpoint(max-tablet-landscape) {
    .pull-right-not-sm, .pull-left-not-sm {
      float: none;
    }

    .pull-right-sm {
      float: right;
    }

    .pull-left-sm {
      float: left;
    }
  }
}

@include breakpoint(tablet-landscape) {
  @include breakpoint(max-desktop) {
    .pull-right-not-md, .pull-left-not-md {
      float: none;
    }

    .pull-right-md {
      float: right;
    }

    .pull-left-md {
      float: left;
    }
  }
}

@include breakpoint(desktop) {
  .pull-right-not-lg, .pull-left-not-lg {
    float: none;
  }

  .pull-right-lg {
    float: right;
  }

  .pull-left-lg {
    float: left;
  }
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background: none;
  border: none;
}

.pb-20 {
  padding-bottom: 5rem !important;
  @include breakpoint(max-tablet-portrait) {
    padding-bottom: 2.5rem !important;
  }
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  @include breakpoint(max-tablet-portrait) {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
}
.w-md-75 {
  @include breakpoint(tablet-portrait) {
    width: 75%;
  }
}
.w-lg-60 {
  @include breakpoint(tablet-portrait) {
    width: 60%;
  }
}
.rounded-border {
  border-radius: 6px;
}

.border-gray {
  border:1px solid $gallery;
  border-radius:6px;
}
.border-bottom-black{
  border-bottom:1.5px solid;
  border-bottom-color: $gray-scale-black;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.dashboard {
  .form-check-input {
    height: 24px;
    width: 24px;
    margin-top: 0;
    accent-color: $dark-ocean;
    @include attention() {
      cursor: pointer;
    }
  }

  .form-check-label {
    font-size: 16px;
    font-weight: 400;
    margin-left: 1rem;
    @include attention() {
      cursor: pointer;
    }
  }
}

.m-negative {
  margin-left: -15px;
  margin-right: -15px;
}