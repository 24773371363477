﻿.leftmenu {
    display: inline-block;
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;

    .leftmenu__item {
        font-size: 1.1rem;
        margin: 0 0 0.4rem 0;
        padding: 0;

        &.active .leftmenu__item-link {
            border-left: solid 0.1rem $tahiti-gold;
        }
    }

    .leftmenu__item-link {    
        color: $black;
        padding: 0.3rem 0.5rem;

        &:hover {
            color: $tahiti-gold;
        }
    }
}
