
@import "../../../styles/abstracts/_variables.scss";

.icon-circle {
    background-color: $beach;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    color: $apricot;
}

.dark {
    span.icon-circle {
        background: none;
        border-color: $white;

        i {
            color: $white;
        }
    }

    p {
        color: $white;
    }

    .btnReload {
        background: none;
        border-color: $white;
        color: $white;
    }

    color: $white;
}
