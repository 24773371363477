﻿.faq-list {
    padding-top: 70px;
           
    h3.title {
        cursor: pointer;
    }

    .question {
        position: relative;
        padding: 0 0 15px 0;
        margin: 0 0 15px;
        border-bottom: 1px solid #e9e9e9;

        @include breakpoint(tablet-landscape) {
            padding-right: 45px;
            padding: 0 0 25px 0;
            margin: 0 0 25px;
        }

        &:last-child {
            border-bottom: 0;
        }

        .icon {
            width: 19px;
            height: 19px;
            background: url(/Static/img/icon-readmoreplus.png) no-repeat;
            text-indent: -9999px;
            position: absolute;
            right: 0;
            top: 2px;
            cursor: pointer;
            -webkit-transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-property: -webkit-transform;
            -moz-transition-property: -moz-transform;
            -o-transition-property: -o-transform;
            transition-property: transform;
        }

        .text {
            position: absolute;
            right: 24px;
            top: 0;
            cursor: pointer;
            text-transform: uppercase;
            color: #606060;
            font-family: Gilroy-SemiBold, sans-serif;

            @include breakpoint(max-tablet-portrait) {
                display: none;
            }
        }

        .toggle {
            padding-bottom: 1rem;
        }
        @include breakpoint(max-tablet-portrait) {
            h3 {
                font-size: 18px;
                line-height: 24px;
                margin: 0;
                padding: 0 40px 0 0;
            }

            p {
                margin: 15px 0 0;
                font-size: 14px;
                line-height: 20px;
            }

            li {
                font-size: 14px;
            }

            .icon {
                top: 0;
            }
        }
    }
}
