// Image Block
.block-container {
    
    &.col-md-5 {
        .topimagecardblock,
        .fullimagecardblock,
        .orderformblock {
            margin-bottom: 20px;
        }
    }

    .topimagecardblock,
    .fullimagecardblock,
    .orderformblock {

        .orderform {
            margin-bottom: 0;

            h3 {
                margin-top: 0;
            }

            p {
                line-height: 22px;
            }

            .price {
                width: 65px;
                height: 65px;
                border-radius: 50%;
                background-color: $tahiti-gold;
                position: absolute;
                top: -16px;
                right: -14px;

                .number {
                    display: block;
                    font-size: 24px;
                    line-height: 24px;
                }

                .description {
                    font-size: 10px;
                }
            }
        }
    }

    .topimagecardblock,
    .fullimagecardblock {

        .button-content-area {
            line-height: 0;
        }

        h2 {
            font-family: Gilroy-SemiBold, sans-serif;
            font-size: 2rem;
            line-height: 25px;
        }

        .text-container {
            min-height: 220px;
            width: 100%;

            p {
                line-height: 22px;
            }

            &.text-align-left {
                text-align: left;

                .buttonblock {
                    .btn {
                        margin-left: 0;
                    }
                }
            }
        }
        // Image block background cover, generic styles
        .background-cover {
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            border-radius: 4px;
            padding: 10px 20px 10px;

            h2,
            h3,
            p {
                margin: 10px 0;
            }

            p.preamble {
                span {
                    font-size: 1rem;
                }
            }
        }
    }

    .background-cover {
        .vertical-align-bottom { 

            a.btn,
            button.btn {
                margin-bottom: 0;
            }
        }
    }

    @include breakpoint(max-tablet-portrait) {
        &:not(:first-child) {
            .block {
                &:first-child {
                    margin-top: 20px;
                }
            }
        }
    }
}

.fullimagecardblock {
    // Full Image Block [Small, Medium, Large, Small-Wide, Medium-Wide]
    .full-image-card {
        position: relative;
        border-radius:6px;

        &.full-image-card--sm {
            .background-cover {
                min-height: 200px;
            }
        }

        &.full-image-card--md {
            .background-cover {
                min-height: 400px;
            }
        }

        &.full-image-card--lg {
            .background-cover {
                min-height: 650px;
            }
        }

        &.vertical-align-middle {
            .background-cover {
                .text-container {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }
            }
        }

        &.vertical-align-bottom {
            .background-cover {
                .text-container {
                    position: absolute;
                    bottom: 0;
                    width: 100%;

                    h2,
                    h3,
                    span {
                        color: white;
                    }
                }
            }
        }

        .background-cover {
            padding: 0;

            .text-container {
                width: 100%;
                padding: 20px;

                &.text-align-left {

                    h2,
                    h3,
                    p {
                        text-align: left;
                    }

                    .btn {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }

                &.text-center {
                    .btn {
                        margin: 10px;
                    }
                }

                &.text-align-right {

                    h2,
                    h3,
                    p {
                        text-align: right;
                    }

                    .btn {
                        float: right !important;
                        margin-right: 0;
                        margin-left: 20px;
                    }
                }

                h2,
                h3,
                p {
                    text-align: center;
                }
            }
        }
    }
}

.topimagecardblock {
    // Top Image Block [Small-fixed, Small-Floating, Large-Fixed, Large-Floating]
    .top-image-card {
        border-radius: 4px;

        &.top-image-card__fixed--sm,
        &.top-image-card__floating--sm {
            .background-cover {
                min-height: 150px;
            }
        }

        &.top-image-card__fixed--md,
        &.top-image-card__floating--md {
            .background-cover {
                min-height: 225px;
            }
        }

        &.top-image-card__fixed--lg,
        &.top-image-card__floating--lg {
            .background-cover {
                min-height: 300px;
            }

            .text-container {
                padding: 20px;
            }
        }

        &.top-image-card__fixed--sm,
        &.top-image-card__fixed--md,
        &.top-image-card__fixed--lg {
            .background-cover {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.top-image-card__fixed--sm,
        &.top-image-card__fixed--md,
        &.top-image-card__fixed--lg {
            background-color: #eaeaea;
        }

        &.top-image-card__floating--sm,
        &.top-image-card__floating--md,
        &.top-image-card__floating--lg {
            background: transparent;
        }

        &.vertical-align-top {
            .text-container {
                padding: 20px 20px 60px;
            }
        }

        &.vertical-align-middle {
            .text-container {
                padding: 40px 20px 40px;
            }
        }
         
        .text-container {
            padding: 60px 20px 20px;

            h2,
            h3,
            p {
                margin: 0 0 10px;
            }

            p.preamble {
                a.ContentLuminous {
                    font-family: Gilroy-SemiBold;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .btn {
                margin-right: 20px;

                &.btn-default-light {
                    @include button-variant($tundora, $mercury, $mercury);
                    color: $tundora;
                }
            }
        }
    }
}

.buttonblock {
    display: inline-block;
}
