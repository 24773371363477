﻿.unexpectednotification {
    background: $white-smoke;

    &.unexpectednotification__myservices {
        margin-top: 0;
    }

    @include breakpoint(large-desktop) {

        &.unexpectednotification__myservices {
            margin-top: 0;
        }
    }

    @include breakpoint(max-tablet-portrait) {
        /*margin-top: 50px;*/

        &.unexpectednotification__myservices {
            margin-top: 0;
        }
    }

    .unexpectednotification__content {
        padding: 1.5rem 1rem 1.5rem 4rem;
        position: relative;

        /*@include breakpoint(max-xsmallphone) {
            padding-top: 3.3rem;
        }*/
    }

    .unexpectednotification__icon {
        background: $silver;
        color: $white;
        font-size: 1.25rem;
        text-align: center;
        height: 3rem;
        width: 3rem;
        line-height: 3rem;
        position: absolute;
        top: 0.875rem;
        left: 0;

        @include border-radius(1.5rem);
    }

    &.unexpectednotification__startpage {
        .unexpectednotification__icon {
            top: 1.5rem;

            /*@include breakpoint(max-xsmallphone) {
                top: 3.3rem;
            }*/
        }
    }



    .unexpectednotification__title {
        font-size: 0.875rem;
        font-family: Gilroy-SemiBold, sans-serif;
        margin: 0.25rem 0;
        text-transform: uppercase;
    }

    .unexpectednotification__text {
        font-size: 1rem;
        margin: 0;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &.Sand {
        background: $dawn-pink;

        .unexpectednotification__icon {
            background: $muddy-waters;
        }

        .unexpectednotification__title {
            color: $muddy-waters;
        }
    }

    &.Green {
        background: $apple-green;

        .unexpectednotification__icon {
            background: $green-haze;
        }

        .unexpectednotification__title {
            color: $green-haze;
        }
    }

    &.Red {
        background: $cinderella;

        .unexpectednotification__icon {
            background: $monza;
        }

        .unexpectednotification__title {
            color: $monza;
        }
    }
}
