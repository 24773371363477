﻿.dashboard {
  background-color: #f7f7f7 !important;
  padding: 30px;
  min-height: 1000px !important;
  overflow-x: hidden;

  @include breakpoint(max-tablet-portrait) {
    padding: 12px;
  }
}
