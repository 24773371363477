﻿.top-intro {
    background: $deep-ocean;
    color: $white;
    padding: 40px 0 40px;

    img {
        position: absolute;
        top: 0;
        left: 20px;
        max-height: 50px;
    }

    .content {
        padding-top: 70px;
    }

    @include breakpoint(tablet-landscape) {

        img {
            left:0;
            max-width:100px;
            max-height:none;
        }

        .content {
            padding-top:0;
            padding-left:140px;
        }
    }
}
.intro-preamble {
    text-align: center;
    color: $gray;
    margin: 30px auto;
    font-size: 20px;
    max-width: 600px;
}

