﻿// BREAKPOINTS
@mixin breakpoint($point) {
    @if $point == large-desktop {
        @media (min-width: 1440px) {
            @content;
        }
    }
    @if $point == max-large-desktop {
        @media (max-width: 1439px) {
            @content;
        }
    }
    @else if $point == desktop {
        @media (min-width: 1280px) {
            @content;
        }
    }
    @else if $point == max-desktop {
        @media (max-width: 1279px) {
            @content;
        }
    }
    @else if $point == tablet-landscape {
        @media (min-width: 992px) {
            @content;
        }
    }
    @else if $point == max-tablet-landscape {
        @media (max-width: 991px) {
            @content;
        }
    }
    @else if $point == tablet-portrait {
        @media (min-width: 768px) {
            @content;
        }
    }
    @else if $point == max-tablet-portrait {
        @media (max-width: 767px) {
            @content;
        }
    }
    @else if $point == max-phone {
        @media (max-width: 480px) {
            @content;
        }
    }
    @else if $point == small-phone {
        @media (min-width: 375px) {
            @content;
        }
    }
    @else if $point == max-smallphone {
        @media (max-width: 374px) {
            @content;
        }
    }
}