.form.receipt {
    text-align: center;

    .summary {
        h3.price {
            text-align: center;
            font-size: 28px;
        }

        p.summary {
            text-align: center;
            margin: 20px 0 30px;
            padding: 0;
            line-height: 1.5;
        }
    }
    .buttons{
        .btn-standard{
            padding:0.5rem;
        }
    }
}