﻿.productpackageblock {
    @include breakpoint(max-tablet-portrait) {
        .row {
            margin-bottom: 0;
        }
    }

    .productpackageblock__background {
        padding-top: 50px;

        @include breakpoint(tablet-portrait) {
            padding-top: 62px;
        }

        @include breakpoint(large-desktop) {
            padding-top: 100px;
        }
    }
}

.productblock-inner {
    &.fraud{
        min-height:unset;
        margin-bottom:10px;
        margin-left:10px;
        margin-top:10px;
        .order-form{
            right:unset;
            margin-top:10px;
            top:unset;
            position:unset;
        }
    }
    @include clearfix;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    background-size: cover;

    @include breakpoint(tablet-portrait) {
        min-height: 660px;
    }

    @include breakpoint(max-tablet-landscape) {
        padding-top: 100px;
    }

    @include breakpoint(max-tablet-portrait) {
        padding-top: 60px;
    }

    .info {
        color: $white;

        @include breakpoint(tablet-landscape) {
            @include vertical-align($position: absolute);
            width: 500px;
        }

        @include breakpoint(desktop) {
            @include vertical-align($position: absolute);
            width: 500px;
        }

        @include breakpoint(large-desktop) {
            @include vertical-align($position: absolute);
            width: 540px;
        }

        h1 {

            img {
                vertical-align: bottom;
                margin: 0 25px 0 0;

                @include breakpoint(max-tablet-portrait) {
                    max-height: 32px;
                    margin: 0 15px 0 0;
                    vertical-align: bottom;
                }

                @include breakpoint(tablet-landscape) {
                    position: relative;
                    top: 0;
                }
            }
        }

        p {
        }
    }

    .order-form {
        background: #333;
        position: relative;

        @include breakpoint(tablet-landscape) {
            width: 360px;
            right: 60px;
            top: 50%;
            margin-top: -170px;
            padding: 20px;
            background: rgba(51,51,51,0.8);
            position: absolute;

            &.order-form--ua {
                margin-top: -125px;
            }
        }

        @include breakpoint(desktop) {
            right: 160px;
        }

        @include breakpoint(max-tablet-landscape) {
            margin:80px -15px 20px;
            padding: 20px;
        }

        @include breakpoint(max-tablet-portrait) {
            margin: 50px -15px 0;
        }

        input {
            width: 100%;
            margin: 0;
            padding: 8px;
            border: 0;
            outline: 0;
            font-size: 16px;

            @include placeholder {
            }
        }

        button {
            border: 0;
            outline: 0;
            width: 100%;

            @include breakpoint(max-tablet-portrait) {
                margin-top: 8px;
            }
        }
    }
}

.productinfo {
    padding: 20px 0 0;

    .included-column {
        text-align: center;

        @include breakpoint(max-tablet-landscape) {
            margin-bottom: 30px;
        }

        @include breakpoint(tablet-landscape) {
            border-right: 1px solid #e7e7e7;
        }

        img {
            max-width: 100%;

            @include breakpoint(max-tablet-portrait) {
                max-width: 80px;
            }

            @include breakpoint(max-tablet-landscape) {
                max-width: 100px;
            }
        }

        &:last-child {
            border-right: 0;
        }

        h3 {
        }

        p {
        }
    }
}
