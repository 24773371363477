﻿.menu {
  .plain {
    background-color: transparent;
    padding-right: 0;
    border: 0;
  }
}
.circle-product {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid $ocean;

  &.zero-state {
    width: 60px;
    height: 60px;
    border: 0;
    background-color: $ice-blue;
    i {
      font-size: 24px;
      color: $deep-ocean !important;
    }
  }

  i {
    font-size: 24px;

    &:first-child {
      color: $ocean;
    }
  }

  @include breakpoint(max-tablet-portrait) {
        
    i {
      font-size: 18px;
    }
  }
}

.menu-item-name {
  padding: 12px 14px;
  .fa-chevron-right {
    top: calc(50% - 10px);
    right: 10px;
    position: absolute;
  }
}

.label-daysleft {
  background-color: $gray;
  padding: 0 4px;
  border-radius: 6px;
}
