
@import "../../../../styles/abstracts/_variables";
@import "../../../../styles/abstracts/_mixins";
@import "../../../../styles/abstracts/_breakpoints";

.product__circle {
    min-width: 146px;
    min-height: 146px;
    text-align: center;
    border-radius: 50%;
    background-color: $beach;
    border: 2px solid $rodeo-dust;

    .items {
        display: flex;
        flex-direction: column;
    }

    .more-info {
        bottom: 8px;
    }

    &.disabled {
        background-color: $gallery;

        i {
            color: $silver;
        }
    }

    span {
        display: block;
        width: 100%;
        text-align: center;

        i {
            color: $orangegold;
        }
    }
}

.daysLeftLabel {
  background-color: $gray;
  border-radius: 6px;
  padding: 0 5px;
  top: 20px;
  right: -15px;
}
