
@import "../../../styles/abstracts/_variables.scss";

.payment-options-pooper-content {
  font-family: Roboto;

  ul {
    list-style-type: none;
    padding: 12px;
    margin: 0;

    li {
      border-radius: 6px;

      .options-link {
        padding: 6px 16px;
      }

    }
  }

}
