
    @import "../../../../styles/abstracts/_breakpoints.scss";
    .dashboard__container .dashboard__wrapper .my-credit-score-chart {
        width: 100%;
        overflow: hidden;
        height: 280px;

        @include breakpoint(max-tablet-landscape) {
            height: 360px;
        }

        @include breakpoint(max-tablet-portrait) {
            height: 360px;
        }

        @include breakpoint(max-phone) {
            height: 280px;
        }

        @include breakpoint(max-smallphone) {
            height: 180px;
        }

        .excellent {
            fill: #57AF46;
            fill-opacity: 1;
        }

        .very-good {
            fill: #B7AE45;
            fill-opacity: 1;
        }

        .good {
            fill: #D8B74D;
            fill-opacity: 1;
        }

        .worse {
            fill: #E09353;
            fill-opacity: 1;
        }

        .not-good {
            fill: #EB585A;
            fill-opacity: 1;
        }

        .gauge-value {
            text {
                font-size: 32px;
                fill: #00A04B;
                stroke: #00A04B;
            }
        }
        /*tspan.gauge-value {
            tspan {
                fill: #00A04B;
                stroke: #00A04B;
            }
        }*/
        .highcharts-title {
            left: 0 !important;
            top: 0 !important;
        }

        .highcharts-label-box {
            stroke: none;
            background-color: #fff;
            fill: #fff !important;
        }
    }

