﻿.order-layer {
    z-index: 135;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 999999;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        /* Put your IE-only styles here. Works for IS 10 & IE 11*/
        .order-layer {
            position: unset;
        }
    }

    .form {
        color: $black;

        .productpackage-price {
            font-size: 28px;
            font-family: Gilroy-SemiBold,sans-serif;
            margin: 30px 0 0;
        }
    }

    .offers {

        .products {
            border-top: 0;

            .product {
                max-width: 770px;
                margin: 0 auto;
                padding: 30px;
                border: 1px solid #ddd;

                &:nth-child(n+2) {
                    border-top: 0;
                }
            }
        }
    }

    .discount {
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;

        h3 {
            cursor: pointer;

            .icon {
                display: inline-block;
                width: 15px;
                height: 15px;
                background: url(/Static/img/icon-faqplus.png) no-repeat;
                background-size: 15px;
                margin-right: 5px;
            }
        }

        input[type="text"] {
            padding: 10px;
            width: 100%;
            max-width: 250px;
        }
    }

    h3.price {
    }

    p.summary {
    }

    .payment {
        width: 184px;
        margin: 0 auto;

        p {
        }

        label {
            display: block;
            margin: 8px 0;
        }
    }

    .personalInformation {
        width: 100%;
        /*max-width: 450px;*/
        margin: 0 auto;

        input[type="text"] {
            width: 100%;
            padding: 0 10px;
            height: 50px;
            line-height: 50px;
            margin: 0;
        }
    }

    .read-more-container {
        display: block;
        padding: 15px;

        .read-more-link {
            margin-right: 20px;
            text-decoration: none;
            color: $dark-ocean;

            i {
                font-weight: 600;
            }
        }

        .read-more-link:active {
            color: $dark-ocean;
        }

        .read-more-link .fa-chevron-up,
        .read-more-link.collapsed .fa-chevron-down {
            display: inline-block;
        }

        .read-more-link.collapsed .fa-chevron-up,
        .read-more-link .fa-chevron-down {
            display: none;
        }
    }

    .conditions {
        max-width: 310px;
        margin: 30px auto;

        label {
            display: inline-block;
            color: $black;

            p {
                display: inline-block;
                margin-bottom: 0;
            }
        }

        .conditions-policy-check-label {
            display: block;
            margin-bottom: 0;

            p {
                display: inline-block;
            }
        }
    }

    .new-overlay {
        .popup {
            max-width: 40%;

            @include breakpoint(max-phone) {
                max-width: 95%;
            }
        }

        .error-popup {
            @include breakpoint(tablet-portrait) {
                max-width: unset;
                width: 13rem;
            }
        }

        .purchase-popup {
            min-height: 30%;
            max-height: 95% !important;
            padding-bottom: 0 !important;

            i {
                position: absolute;
                right: 30px;
                z-index: 10000;
            }

            .purchase-popup-info-text {
                border-top: solid 1px #c0c0c0;
                padding-top: 5px;
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        @media screen and (max-width: 1199px) and (min-width: 481px) {
            .purchase-popup {
                max-width: 60% !important
            }
        }

        @media screen and (min-width: 481px) {
            .purchase-popup {
                min-width: 456px !important;
            }
        }

        #orderLayerValidation input {
            width: 100% !important;
        }

        .conditions {
            margin: 0px auto;

            @include breakpoint(max-phone) {
                font-size: 16px;
            }
        }

        .buttons {
            #orderMultipleChoiceBtn {
                margin-left: 0;
                color: $white;
            }

            #orderProductPackeBtn {
                color: $white;
            }
        }

        .conditions-information {
            max-width: 480px;
            margin: 20px auto;
            max-height: 400px;
            overflow-y: scroll;
            border: 1px solid #c0c0c0;
            padding: 10px;
        }

        .buttons {
            padding-bottom: 40px;

            button {
                @include breakpoint(max-phone) {
                    width: 100%;
                }

                min-width: 200px;
                height: 50px;
                background: #f07d00;
                outline: 0;
                border: 0;
                /*margin: 0 10px;*/
                &:focus,
                &:hover {
                    background: #eb5e00;
                }

                &:active {
                    background: darken(#eb5e00, 5%);
                    -webkit-box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.25);
                    box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.25);
                }

                &.disabled,
                &.disabled-custom {
                    opacity: 0.5;

                    &:focus,
                    &:hover {
                        background: #f07d00;
                    }
                }
            }
        }
    }

    .dibs-layer,
    .order-layer {
        .close {
            position: absolute;
            top: 30px;
            right: 30px;
            width: 30px;
            height: 30px;
            background: url(/Static/img/icon-close-dark.png) no-repeat;
            text-indent: -9999px;
            cursor: pointer;

            &:hover {
                @include opacity(0.8);
            }
        }
    }

    .order-layer .new-overlay .close {
        position: unset;
        top: unset;
        right: unset;
        width: unset;
        height: unset;
        background: unset;
        text-indent: unset;
        cursor: unset;
    }

    .products {
        padding-top: 1.5rem;
        border-top: 1px solid #ddd;
        text-align: left;

        .product {
            padding: 30px 0;
            border-bottom: 1px solid #ddd;
            text-align: left;

            &.active {
                background: #ececec;
            }

            @include breakpoint(max-tablet-portrait) {
                padding: 30px;
            }

            @include breakpoint(tablet-portrait) {
                .inner {
                    max-width: 630px;
                    min-height: 165px;
                    margin: 0 auto;
                    padding-left: 200px;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        max-width: 174px;
                    }

                    .info {
                        @include vertical-align(absolute);
                    }
                }
            }

            @include breakpoint(max-tablet-portrait) {
                .inner {
                    img {
                        display: none;
                    }
                }
            }
        }
    }

    .summary {
        padding: 20px;
    }

    .indicator {
        position: absolute;
        top: 34px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

        .bullet {
            width: 20px;
            height: 20px;
            background: #ddd;
            float: left;
            margin-right: 30px;
            position: relative;
            @include border-radius(15px);

            .connector {
                display: block;
                width: 32px;
                height: 4px;
                background: #ddd;
                display: none;
                position: absolute;
                top: 8px;
                left: -30px;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active,
            &.completed {
                background: #f07d00;

                .connector {
                    background: #f07d00;
                }
            }

            &:nth-child(n+2) .connector {
                display: block;
            }
        }
    }


    .form.receipt {
        padding: 60px 0 20px;

        @include breakpoint(tablet-portrait) {
            padding: 72px 0 30px;
        }

        @include breakpoint(large-desktop) {
            padding: 110px 0 30px;
        }

        .buttons {
            margin-top: 30px;

            .btn-standard {
                margin: 0 10px 10px;
            }
        }
    }

    #order-overlay {
        @extend .overlay;
        z-index: 134;
    }
}
.form.receipt {
    .products {
        .product {
            .inner {
                .info {
                    text-align: center;

                    p {
                        display: contents;
                    }
                }
            }
        }
    }
}
