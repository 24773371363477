﻿//GLOBAL CASCADE MENU SETTINGS
.cascade__menu-top {
  a {
    color: $white;
  }

  .nav-link i {
    right: 0;
    top: calc(50% - 8px);
  }

  .nav-pills {
    a {
      &.nav-link.active, &.nav-link:hover {
        background-color: unset;
      }
    }
  }
}

.cascade__menu-list {
  list-style-type: none;
  li {
    p {
      flex-basis: 100%;
    }
  }
  li > a > h4 {
    flex: 0 0 100%;
  }
}
.cascade__menu-expanded {
  z-index: 120;
}

.cascade__menu-top, .cascade__menu-expanded {
  background-color: $night-rider;

  a {
    i {
      color: $white;
      &.fa-life-ring {
        color: $silver;
      }
    }

    span, i, h4, h5 {
      border-bottom: 2px solid transparent;
    }

    @include attention() {
      text-decoration: none;
      i {
        color: $orangegold;
      }
      span, h4 > span, h5 {
        border-color: $ocean;
      }
    }
    &:not(.active) {
        span:hover {
          border-color: $ocean;
        }
    }
  }

  .cascade__menu-trigger {
    a {
      &:after {
        font-family: "Font Awesome 5 Pro";
        content: '\f078';
        color: $silver;
        position: absolute;
        right: 0;
        top: calc(50% - 12px);
      }

      &.active {
        &:after {
          color: $white;
        }
      }
    }
  }

  .full-image-card, .background-cover {
    border-radius: 8px;
  }
}
