@import "bootstrap";

// Abstract imports
@import "abstracts/parts";

// Vendor imports
@import "vendor/parts";

// Base imports
@import "base/parts";

// Components imports
@import "components/parts";

// Page imports
@import "pages/parts";

// Layout imports
@import "layout/parts";

@import "@fortawesome/fontawesome-pro/css/all.min.css";

@import "./css/MyFontsWebfontsKit.css";
@import "./css/editmode.css";

@import '@nds/vue/dist/style.css';


@import "~highcharts/css/highcharts.css";
.idprotection__noevents i {
    border-radius: 50%;
    padding: 20px;
    font-size: 40px;
} 

:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 0px; 
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

body {
    height: 100%;
}