﻿.btn {
  padding: 0.5rem 1rem;
  font-weight: 500;
  @include border-radius(6px);
  
  &.disabled,
  &:disabled {
    background: $gallery;
    border-color: $gallery;
    color: $gray;
    opacity: 1;
  }
}

.btn-sm {
  padding: 0.375rem 0.5rem;
}

.btn-lg {
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  
  @include breakpoint(max-tablet-portrait) {
    padding: 0.5rem 1rem;
  }
}

.btn-xl {
  padding: 1rem 1.75rem;
  @include breakpoint(max-tablet-portrait) {
    padding: 0.75rem 1.25rem;
  }
}
.btn-wide {
  min-width: 220px;
}

.btn-standard {
  color: $white;
  text-align: center;
  text-decoration: none;
  border: none;

  &:focus,
  &:hover {
    color: $white;
    background: $christine;
    text-decoration: none;
  }

  &:active {
    color: rgba($white, 0.75);
    background: $christine;
    -webkit-box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.25);
    text-decoration: none;
  }
}

.btn-enento-primary {
  background: $orangegold;
  color: $white;
  font-weight: 300;

  @include attention() {
    background: $darkorange;
    color: $white;
  }

  &:focus:active {
    background-color: $brownorange;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $summer-sky;
    outline: 2px solid $white;
  }
}

.btn-enento-secondary, .btn-enento-feedback-error {
  border: 2px solid $gray;
  background-color: $white;

  @include attention() {
    background-color: $gallery;
  }

  &.disabled,
  &:disabled {
    background: transparent;
  }

  &:focus:active {
    background-color: $silver;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $summer-sky;
    outline: 2px solid $white;
  }
}
.btn-enento-blue {
  background: $dark-ocean;
  border-color: $dark-ocean;
  color: $white;
  @include attention() {
    background: $deep-ocean;
    border-color: $deep-ocean;
    color: $white;
  }
  &.disabled,
  &:disabled {
    background: $gallery;
    border-color: $gallery;
    color: $gray;
    opacity: 1;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $summer-sky;
    outline: 2px solid $white !important;
  }
}
.btn-enento-text {
  padding: 0.5rem;
  color: $dark-ocean;
  @include attention() {
    color: $dark-ocean;
    background: $lightgray-hover;
  }
  &.disabled,
  &:disabled {
    color: $gray;
  }
  &:focus:active {
    color: $dark-ocean;
    background-color: $lightgray-pressed;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $summer-sky;
    outline: 2px solid $white;
  }
}
.btn-enento-icon,
.btn-enento-icon-sm,
.btn-enento-icon-light,
.btn-enento-icon-sm-light {
  border-radius: 50%;
  &:focus:active {
    outline: 0;
    box-shadow: none;
  }
}
.btn-enento-icon,
.btn-enento-icon-light {
  padding: 10px;
}
.btn-enento-icon-sm,
.btn-enento-icon-sm-light {
  padding: 8px;
}
.btn-enento-icon,
.btn-enento-icon-sm {
  @include attention() {
    background: $lightgray-hover;
  }
  &:focus:active {
    background: $lightgray-pressed;
  }
  &.disabled,
  &:disabled {
    color: $silver;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $summer-sky;
    outline: 2px solid $white;
  }
}
.btn-enento-icon-light,
.btn-enento-icon-sm-light {
  @include attention() {
    background: $darkgray-hover;
  }
  &:focus:active {
    background: $darkgray-pressed;
  }
  &.disabled,
  &:disabled {
    color: $gray;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $summer-sky;
    outline: 2px solid $white;
  }
}

.btn-enento-feedback-error {
  color: $white;
  background-color: $feedback-error;

  @include attention() {
    color: $white;
    background-color: $feedback-error-hover;
    border-color: $feedback-error-hover;
  }
  &:disabled {
    color: $gray;
  }
  border-color: $feedback-error;
}

.btn-default, .btn-standard, {
  display: inline-block;
  background: $tahiti-gold;
  box-sizing: border-box;
  outline: 0;
}

.btn-transparent {
  border: 1px solid $white;
}

.btn-default-light {
  background-color: $gallery;
  border-color: $mercury;
  @include attention() {
    background-color: $alto;
    color: $tundora;
  }
}

.btn-default-light-border {
  background-color: transparent;
  border-color: lightgray;
  color: lightgray;

  @include attention() {
    background-color: $alto;
    color: $tundora;
  }
}

.btn-default--white {
  background: $white;
  border-color: transparent;
  color: $black;

  @include attention() {
    background: $gallery;
  }
}

.btn-modal-close {
  /*background:#fff;
  color:$black;*/
  border: solid 1px #ccc;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

