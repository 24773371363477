<template>
    <TabbedPageTemplate testId="risk-forecast-page">
        <NdsCard
            :heading="{ title: model.headline.value, type: 'h3', prefix: { prefix: 'far', iconName: 'tachometer' } }">
            <NdsAccordion :accordions="[{ id: moreInformationId, title: 'Vad är UC Riskprognos?' }]">
                <template #[moreInformationId]>
                    <NdsCard>
                        <NdsText v-if="model.description.value" :text="model.description.value" isRichText tight />
                        <NdsText :text="model.readMoreLinkTitle.value" tight class="read-more-link-title" />
                        <NdsLink :href="model.readMoreLink.value"
                            :prefix="{ prefix: 'far', iconName: 'external-link' }">{{ model.readMoreLinkLabel.value }}
                        </NdsLink>
                    </NdsCard>
                </template>
            </NdsAccordion>
            <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
                <NdsLoader />
            </div>
            <div v-else-if="failed" class="mt-4">
                <Reloader @reload="getCreditInfo" />
            </div>
            <template v-else>
                <div class="d-flex flex-column align-items-center flex-fill mt-14 charts-container">
                    <div class="d-flex align-items-center flex-column doughnut-chart-wrapper">
                        <NdsHeading :title="model.forecastRatingChartTitle.value" type="h4" tight />
                        <NdsRiskDoughnutChart subTitle="Ditt betyg är" :value="riskGrade" />
                        <div v-if="riskGrade === 0">
                            <p v-html="model.forecastUnknownScoreText.value" />
                        </div>
                    </div>
                    <div class="d-flex flex-column mt-3 nds-plot-chart-container">
                        <NdsHeading :title="model.forecastPlotChartTitle.value" type="h4" tight
                            class="align-self-center" />
                        <NdsRiskPlotChart :emptyTitle="model.forecastPlotChartEmptyTitle.value"
                            :chartData="riskPlotChartData" :legendItems="[
                                {
                                    label: 'Utmärkt, Mycket bra & Bra',
                                    colorClass: 'bg-feedback-success-500', style: 'circle'
                                },
                                {
                                    label: 'Mindre bra',
                                    colorClass: 'bg-feedback-warning-500', style: 'circle'
                                },
                                {
                                    label: 'Svag',
                                    colorClass: 'bg-feedback-error-500', style: 'circle'
                                },
                                {
                                    label: 'Okänd',
                                    colorClass: 'bg-gray-300', style: 'circle'
                                }
                            ]" />
                    </div>
                </div>
                <div class="mt-2" v-if="riskGrade === 0">
                    <p v-html="model.forecastUnknownScoreText.value" />
                </div>
                <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: UC" tight />
            </template>
        </NdsCard>
    </TabbedPageTemplate>
</template>

<script setup>
import { useStore } from 'vuex';
import { onMounted, computed, ref } from 'vue';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import Reloader from '../components/Reloader.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY } from '../Scripts/store/modules/customerCreditNotificationsContex';
import { NdsLoader, NdsText, NdsCard, NdsHeading, NdsRiskDoughnutChart, NdsFooter, NdsLink, NdsAccordion, NdsRiskPlotChart } from '@nds/vue';

const props = defineProps({
    model: Object
});

const store = useStore();
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const riskGrade = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.creditScore?.riskGrade ? store.state.customerCreditInfoContex.customerCreditInfo.creditReport.creditScore.riskGrade * 10 : 0);
const creditwatchScoreList = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.creditwatchScoreList);
const moreInformationId = ref('moreInformationId');

const formatRiskPlotChartData = (scoreList) => {
    if (!scoreList || scoreList.length === 0) return [];

    const formattedData = scoreList.map(({ scoreDate, riskForecast }) => {
        const adjustedRiskForecast = (riskForecast > 0 && riskForecast < 0.1) ? 0.1 : riskForecast;
        return {
            timestamp: Number(scoreDate),
            value: Math.round(adjustedRiskForecast * 10),
        };
    });

    return formattedData.every(({ value }) => value === 0) ? [] : formattedData;
};

const riskPlotChartData = computed(() => formatRiskPlotChartData(creditwatchScoreList.value));

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY, { filterCategory: "UC_SCORE" });
    if (!store.state.customerCreditInfoContex.loaded) {
        store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
    }
});

const getCreditInfo = () => {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
<style scoped>
.read-more-link-title {
    font-weight: 500;
    margin-bottom: 8px;
}

.charts-container {
    gap: 1rem;
}

.doughnut-chart-wrapper {
    gap: 1rem;
}

.nds-plot-chart-container {
    width: 100%;
}
</style>