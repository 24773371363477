﻿.dashboard__container {
    .site-content {
        padding-top: 0;
        padding-bottom: 0;
        background-color: #f7f7f7;

        .creditwatch-title {
            padding-top: 1rem;
            position: relative;
            width: 100%;

            @include breakpoint(max-tablet-portrait) {
                margin-left: 10px;
            }

            > a {
                color: $white;
            }
        }

        .new-creditwatch-icon-wrapper {
            position: relative;
            padding: 0.5rem;

            p {
                margin: 0;
            }

            i {
                position: absolute;
                right: 13px;
                top: calc(50% - 7px);

                @include attention() {
                    cursor: pointer;
                }
            }
        }

        @include breakpoint(tablet-portrait) {

            .large-padding, .dashboard__card.large-padding {
                padding-left: 96px;
                padding-right: 96px;
            }
        }

        .dashboard__card {
            background-color: $white;
            margin-bottom: 1rem;
            padding: 1rem;
        }

        .under-construction {
            padding: 2rem;
            background-color: #FAF7F1;
            text-align: center;

            img {
                width: 100%;
            }
        }

        .page-title {
            padding: 10px;
        }
    }

    .tablesaw-all-cols-visible {
        .tablesaw-advance {
            display: none;
        }
    }

    i.fa-question-square {
        color: #BE8C64;
        cursor: pointer;
    }

    .btn-showcustomerreport {
        margin-bottom: 1rem;
    }

    @include breakpoint(desktop) {
        .under-construction-desktop {
            display: block;
        }

        .under-construction-mobile {
            display: none;
        }
    }

    @include breakpoint(max-phone) {
        .under-construction-desktop {
            display: none;
        }

        .under-construction-mobile {
            display: block;
        }
    }

    .toggleButton {
        background-color: $white;
        border-radius: 6px;
        float: right;
        margin-bottom: 10px;
        margin-top: 30px;
        border: 1px solid $gray;
        font-size: 0.875rem;
        height: 38px;
        min-width: 143px;
        /*padding:6px 0px 0px 12px;*/
    }

    .wide-button {
        background-color: $orangegold;
        color: white;
        border-radius: 6px;
        padding: 12px 24px 12px 24px;
        width: 226px;
        height: 48px;
        margin-bottom: 1rem;
        cursor: pointer;

        @include breakpoint(max-phone) {
            margin: 0 auto;
            margin-bottom: 1rem;
        }
    }

    .gray-circle, .emperor-circle {
        background-color: $gallery;
        padding: 10px;
        color: #F39200;
        border-radius: 50%;
    }

    .emperor-circle {
        background-color: $emperor;
    }

    .breadcrumbs-row {
        @include breakpoint(max-phone) {
            display: none;
        }

        .breadcrumbs {
            padding-top: 20px;
            padding-bottom: 20px;

            .first-level-page-title {
                font-size: 1.5rem;

                .gray-circle {
                    margin-right: 15px;
                }

                .fa-chevron-right {
                    margin-left: 15px;
                }
            }

            .second-level-page-title {
                font-size: 1.25rem;
                margin-left: 15px;

                .gray-circle {
                    margin-right: 10px;
                }
            }
        }
    }

    .intro-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        /*opacity: 0.8;*/
        top: 0;
        z-index: 10000;

        .intro-container {
            padding-top: 4rem;

            .btn-close {
                position: absolute;
                right: 25px;
                top: 5px;
                z-index: 5;
                cursor: pointer;
            }

            @include breakpoint(max-tablet-portrait) {
                padding-top: 0;
            }

            .carousel {
                margin: 0 auto;
                float: unset;

                @include breakpoint(max-tablet-portrait) {
                    padding: 0;
                }

                .carousel-indicators {
                    bottom: 10px;
                }

                .carousel-indicators .active {
                    background-color: #000;
                    width: 18px;
                    height: 18px;
                }

                .carousel-indicators li {
                    background-color: #ccc;
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                }

                .nav-buttons {
                    position: absolute;
                    bottom: 10px;
                    width: 100%;
                    z-index: 15;

                    a {
                        float: left;
                        margin-left: 10px;
                        color: #008EAA;
                        text-decoration: underline;
                    }

                    button {
                        float: right;
                        margin-right: 40px;
                        background-color: $orangegold;
                        color: white;
                        padding: 6px;
                        border: none;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
