html {
  font-size: 16px;
  body {
    font-family: Roboto;
    background: $white;
    color: $emperor;
  }
}

h1, h2, .enento__h1, .enento__h2 {
    font-family: Gilroy-Bold, sans-serif;
    font-weight: normal;
}
h3, h4, .enento__h3, .enento__h4 {
    font-family: Gilroy-SemiBold, sans-serif;
    font-weight: normal;

    b, strong {
        font-family: Gilroy-Bold, sans-serif;
        font-weight: normal;
    }
}

h1, .enento__h1 {
    font-size: 3em;
    line-height: 4rem;

    @include breakpoint(max-phone) {
        font-size: 2.125rem;
        line-height: 2.5rem;
    }
}

h2, .enento__h2 {
    font-size: 2em;
    line-height: 2.5rem;

    @include breakpoint(max-phone) {
        font-size: 1.75rem;
        line-height: 2rem;
    }
}

h3, .enento__h3 {
    font-size: 1.5em;
    line-height: 2rem;

    @include breakpoint(max-phone) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

h4, .enento__h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;

    @include breakpoint(max-phone) {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}

h5 {
  font-weight: 700;
  font-size: 1rem; 
  line-height: 1.5rem;
  @include breakpoint(max-phone) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.375rem;
  @include breakpoint(max-phone) {
    font-size: 12px;
    line-height: 1.125rem;
  }
}

p, span, label, aside, a, td {
  font-size: 1rem;
  line-height: 1.5rem;
  &.body-large {
    font-size: 1.375em;
    line-height: 33px;
  }
  &.body-small {
    font-size: 0.875rem;
    line-height: 22px; 
  }
  &.body-xsmall {
    font-size: 0.75rem;
    line-height: 18px;
  }
}
h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
  font-size: inherit;
  line-height: inherit;
}
label {
  font-size: 1em;
  line-height: 1.5rem;
  @include breakpoint(max-phone) {
    font-size: 0.75em;
    line-height: 1.125rem;
  }
}
.font-weight-medium {
  font-weight: 500;
}
.hover-pointer {
  @include attention() {
    cursor: pointer;
  }
}
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.gilroy-semibold {
  font-family: Gilroy-SemiBold;
}
.gilroy-bold {
  font-family: Gilroy-Bold;
}
.fa-18 {
  font-size: 18px;
}
.fa-h3-size {
  font-size: 1.375em;
  @include breakpoint(max-phone) {
    font-size: 1.25em;
  }
}

.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-left: 32px;
}
.ml-32 {
  margin-bottom: 32px;
}
.mx-32 {
  margin: 32px 0;
}
.my-32 {
  margin: 0 32px;
}
.pl-32 {
  padding-bottom: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pr-32 {
  padding-right: 32px;
}


