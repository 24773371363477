﻿.breadcrumb__item {

    &:not(:first-child) {
        &:before {
            font-family: "Font Awesome 5 Pro";
            content: "\f054";
            padding: 6px;
            color: $black;
            font-size: 8px;
            position: relative;
            top: -2px;
        }
    }

    &:not(:last-child) {
        @include attention() {
            color: $black;
        }
    }
}
