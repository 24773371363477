
@import "../../../styles/abstracts/_variables.scss";

.fraudbarrier__wrapper-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: $ice-blue;
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem 1rem 1rem 4rem;
}

.fraudbarrier-review-item {
  border: 1px solid $gallery;
  border-radius: 6px;

  &:before {
    font-family: "Font Awesome 5 Pro";
    content: '\f15b';
    font-size: 1.25em;
    font-weight: 400;
    position: absolute;
    top: calc(50% - 15px);
    left: 20px;
    color: $deep-ocean;
  }

  span {
    padding-left: 2rem;
  }
}

.status-label {
  color: $gray;
}

.upload-area {
  background-color: #ECF7F9;
  border: 1px dashed #333333;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  min-height: 235px;
}

.police-report-drop-zone {
  margin: 30px 0;
  padding: 15px;
  border: 2px dashed #dedede;
  color: #999;
  text-align: center;

  .file-list,
  .uploaded-file-list {
    padding: 0;
    margin: 0 0 15px 0;
    text-align: left;
    color: #333;

    li {
      list-style: none;
      overflow: hidden;
      white-space: nowrap;

      a {
        padding-right: 5px;
      }

      .fa-check {
        /*color: $green-haze;*/
        padding-right: 5px;
      }
    }

    label {
      color: #f07d00;
      cursor: pointer;
      margin: 0;
    }
  }

  label {
    cursor: pointer;
  }
}

.fraudbarrier__wrapper-header {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Pro";
    font-size: 2em;
    top: calc(50% - 24px);
    left: 15px;
    font-weight: 400;
    position: absolute;
    color: $night-rider;
  }

  &.fraudbarrier-approved:before {
    content: '\f058';
  }

  &.fraudbarrier-awaitingapproval:before {
    content: '\f06a';
  }

  &.fraudbarrier-rejected:before {
    content: '\f071';
  }
}
