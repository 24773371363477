
@import "../../../styles/abstracts/_variables.scss";

.btn-enento-secondary {
    border: 1px solid $gray;
    font-weight: 500;
    background-color: inherit;

    span {
        line-height: normal;
    }
}

.btn-upload {
    background-color: $dark-ocean;
}

.file-list {
    list-style-type: none;
}

.fa-upload {
    color: $orangegold;
}
