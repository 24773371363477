﻿#search-layer {
    z-index: 115;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    .container {
        position: relative;
        z-index: 2;
    }
}

.site-search-wrapper {
    margin-top: 80px;

    @include breakpoint(tablet-portrait) {
        margin-top: 100px;
    }

    @include breakpoint(desktop) {
        margin-top: 120px;
    }
}

.site-search {
    max-width: 970px;
    margin: 0 auto;
    padding: 0 15px;
}

.site-search__label {
    display: block;
    font-size: 1rem;
    color: $gallery;
    margin-bottom: 0.5rem;
}

.site-search__box {
    position: relative;
    background: $white;
    padding-right: 50px;

    @include border-radius(3px);
}

.site-search__text {
    color: #000;
    width: 100%;
    padding: 1rem;
    border: 0;
    outline: 0;
    font-size: 1rem;
    background: none;

    @include breakpoint(tablet-portrait) {
        font-size: 1.25rem;
    }
}

.site-search__button {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    background: $white;
    color: $muddy-waters;
    border: 0;
    outline: 0;
    margin: -1.125rem 0 0;
    font-size: 1.5rem;
}

.site-search--page {
    @extend .site-search;
    margin: 1rem 0 1.5rem;
    padding: 0;
    width: 100%;
    max-width: 100%;

    .site-search__label {
        color: $emperor;
    }
}

.site-search--autocomplete-visible {
    .site-search__box {
        @include border-radius-corners(3px, 3px, 0, 0);
    }
}

.site-search__loading {
    padding: 1rem 0.5rem;
    color: $white;
    font-size: 1.125rem;

    .fa {
        font-size: 1.5rem;
        position:relative;
        top:2px;
    }
}
