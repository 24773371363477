﻿.productteaserblock {
    background: $white;

    .row {
        position: relative;
    }
    
    .col-info {
        text-align: center;
        position: relative;

        .teasertext {
            font-size: 20px;
        }

        h2 {
            font-size: 44px;
            @include breakpoint(max-tablet-portrait) {
                font-size: 28px;
            }
        }

        h3 {
            color: $tahiti-gold;
            font-size: 36px;
            
            font-family: Gilroy-Semibold, sans-serif;
            @include breakpoint(max-tablet-portrait) {
                font-size: 24px;
            }
        }

        .btn-standard {
            margin-top: 16px;
        }

        .inner {
            @include breakpoint(desktop) {
                padding-right: 20px;
            }
        }
        @include breakpoint(max-tablet-landscape) {
            h2 {
                font-size: 32px;
            }
            h3 {
                font-size: 24px;
            }
        }


        @include breakpoint(max-tablet-portrait) {
            padding: 2rem 0 1rem 0;

            h2 {
                font-size: 32px;
            }

            h3 {
                font-size: 24px;
                margin-bottom: 0;
            }

            p {
                font-size: 16px;
            }

            .image {
                margin-bottom: 15px;

                img {
                    max-width: 200px;
                }
            }

            .teasertext {
                font-size: 16px;
            }
        }

        @include breakpoint(tablet-portrait) {
            min-height: 300px;
            width: 100%;
            float: left;
            text-align: left;
            padding-top: 2rem;
            padding-right: 340px;
            padding-left: 10px;

        }

        @include breakpoint(tablet-landscape) {
            min-height: 400px;
            width: 100%;
            float: left;
            text-align: left;
            padding-top: 4rem;
            padding-right: 440px;

            p {
                font-size: 18px;
            }
        }
    }

    img.teaserimage {
        @include breakpoint(tablet-portrait) {
            @include vertical-align(absolute);
            right: 10px;
            max-width: 300px;
        }

        @include breakpoint(tablet-landscape) {
            max-width: 400px;
            right: 0;
        }
    }

    .image {
        @include breakpoint(tablet-landscape) {
            max-width: 400px;
        }

        img {
            margin: 20px 0 0;
        }
    }

    &.rightAligned {
        .col-info {
            padding-right: 0;

            .inner {
                padding-left: 0;
                padding-right: 0;

                @include breakpoint(tablet-portrait) {
                    padding-left: 340px;
                    padding-right: 10px;
                }

                @include breakpoint(tablet-landscape) {
                    padding-left: 440px;
                    padding-right: 0;
                }

                @include breakpoint(desktop) {
                }
            }
        }

        img.teaserimage {
            @include breakpoint(tablet-portrait) {
                left: 10px;
            }

            @include breakpoint(tablet-landscape) {
                left: 10px;
            }
        }
    }
}
