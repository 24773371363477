/* MARGIN TOP */
.mt8 {
    margin-top: 8px !important;
}

.mt16 {
    margin-top: 16px !important;
}

.mt24 {
    margin-top: 24px !important;
}

.mt32 {
    margin-top: 32px !important;
}

.mt48 {
    margin-top: 48px !important;
}

/* MARGIN BOTTOM */
.mb0 {
    margin-bottom: 0px !important;
}

.mb8 {
    margin-bottom: 8px !important;
}

.mb16 {
    margin-bottom: 16px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb24 {
    margin-bottom: 24px !important;
}

.mb32 {
    margin-bottom: 32px !important;
}

.mb48 {
    margin-bottom: 48px !important;
}

/* MARGIN LEFT */
.ml8 {
    margin-left: 8px !important;
}

.ml16 {
    margin-left: 16px !important;
}

.ml24 {
    margin-left: 24px !important;
}

.ml32 {
    margin-left: 32px !important;
}

.ml48 {
    margin-left: 48px !important;
}

/* MARGIN RIGHT */
.mr8 {
    margin-right: 8px !important;
}

.mr16 {
    margin-right: 16px !important;
}

.mr24 {
    margin-right: 24px !important;
}

.mr32 {
    margin-right: 32px !important;
}

.mr48 {
    margin-right: 48px !important;
}
/* PADDING TOP/BOT */
.p8 {
    padding: 5rem 10rem 5rem 0;

    @include breakpoint (max-tablet-landscape) {
        padding: 3rem 0; 
    }
}
.p10 {
    padding: 10rem 0;
@include breakpoint (max-tablet-landscape) {
    padding: 5rem 0;
}
}
.p12 {
    padding: 12rem 0;
    @include breakpoint (max-tablet-landscape) {
        padding: 5rem 0;
    }
}
.p14 {
    padding: 14rem 0;
    @include breakpoint (max-tablet-landscape) {
        padding: 6rem 0;
    }
}
.p16 {
    padding: 16rem 0;
    @include breakpoint (max-tablet-landscape) {
        padding: 6rem 0;
    }
}