
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_mixins.scss";

.cascade__menu__mobile-wrapper {
  z-index: 20;

  ul {
    list-style-type: none;
  }

  .cascade__menu__mobile {
    background-color: $night-rider;
    top: 57px;
    bottom: 0;
    left: 0;
    right: 0;

    .active {
      border-radius: 8px;
      background-color: $emperor-hover;
    }

    .cascade__menu__mobile-border {
      border-top: 1px solid $emperor;
    }

    .cascade__menu__mobile-link {
      @include attention() {
        cursor: pointer;
      }
    }

    .cascade__menu__mobile__collapse-header {
      span:first-child {
        color: $white;

        i {
          color: $orangegold;

          &.fas {
            font-size: 1rem;
          }
        }
      }

      &:after {
        font-family: "Font Awesome 5 Pro";
        content: '\f077';
        color: $white;
        position: absolute;
        right: 25px;
        top: calc(50% - 13px);
      }
    }

    .collapsed {
      .cascade__menu__mobile__collapse-header {
        span:first-child {
          color: $silver;

          i {
            color: $silver;
          }
        }

        &:after {
          color: $silver;
          content: '\f078';
        }
      }
    }


    a {
      text-decoration: unset;
      color: $silver;

      .btn {
        color: $white;
        border-radius: 8px;
        margin-left: 1rem;
      }
    }

    a:not(.btn) {
      display: block;
      width: 100%;
    }
  }

  .cascade__menu__mobile__card-wrapper {
    padding-left: 0.25rem;
    padding-right: 0.5rem;
  }

  .cascade__menu__mobile__card {
    border: 1px solid $emperor;
    border-radius: 6px;

    p {
      font-size: 12px;
      color: $silver;
    }
  }

}
