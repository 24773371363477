
.wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.form-control {
  padding-left: 30px;
}

.event-text {
  padding-top: 0.75rem;
}

.tertiary-title-wrapper {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
