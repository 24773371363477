
@import "../../../styles/abstracts/_mixins.scss";
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";

.hr-margin {
  margin-top: 26px;
}

.dashboard-side-bar {
  list-style-type: none;
  background-color: $night-rider;
  min-height: 1000px;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }

  hr {
    border-color: $emperor;
  }

  li {
    position: relative;

    a {
      color: $gallery;

      @include attention() {
        text-decoration: none;
      }

    }

    &:not(.active) {
      @include attention() {
        border-radius: 8px;
        background-color: $emperor-hover;
      }
    }

    .has-notification:after {
      content: "";
      display: inline-block;
      margin-left: 15px;
      width: 8px;
      height: 8px;
      background: $feedback-error;
      border-radius: 50%;
    }

    &.active {
      background-color: $emperor;
      border-radius: 8px;

      >a {
        .menu-item-name {
          text-decoration: none;
        }

        .circle-product {
          background-color: $ocean !important;

          i {
            color: #fff !important;
          }
        }

        i {
          color: $orangegold;
        }
      }
    }
  }

  .sidebar-submenu {
    font-size: 0.875rem;
    list-style-type: none;

    li:first-child {
      margin-top: 1.5rem;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}
