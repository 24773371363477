<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center py-4">
      <div class="circle-product zero-state input-group-text justify-content-center mb-3">
        <i :class="icon" class="fa-fw color-deep-ocean" />
      </div>
      <div class="break" />
      <NdsText v-if="isRichText" isRichText tight :text="text" class="text-center" />
      <NdsText v-else tight :text="text" class="zero-state-text text-center" />
      <div class="break" />
    </div>
  </div>
</template>
<script setup>
import { NdsText } from '@nds/vue';

defineProps({
  icon: { type: String, required: true },
  text: { type: String, required: true },
  isRichText: { type: Boolean, required: false, default: false },
});
</script>
<style scoped>
  .zero-state-text{
    font-weight: 500;
  }
</style>