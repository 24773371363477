
@import "../../../styles/abstracts/_variables.scss";

.fraudbarrier__upload-wrapper i {
  left: 11px;
  /* top: calc(50% - 9px); */
  top: 20px;
}

#generalErrorMessage {

  strong,
  i.alert-icon {
    color: $feedback-error;
  }

  p {
    color: $emperor;
  }

  strong,
  p {
    padding-left: 35px;
  }

  &.hide {
    display: none;
  }
}

.alert-icon {
  left: 10px;
}

.alert-danger {
  border-radius: 0;
}

.enento__toggle {
  margin-right: 30px;
}
