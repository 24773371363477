
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.sidebar-container {
  background: $night-rider;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }
}
