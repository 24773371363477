﻿.article__page {
  .article__page-img {
    background-size: cover!important;
    height: 400px;
    @include breakpoint(max-tablet-landscape) {
      height: 200px;
    }
  }
  .article__page-mainbody img {
    max-width: none;
    padding-top: 1rem;
    margin: 0 -110px;
    width: 130%;
    height: auto;
    @include breakpoint(max-desktop) {
      margin: 0 -95px;
      width: 131%;
    }
    @include breakpoint(max-tablet-landscape) {
      margin: 0 -75px;
      width: 133%;
    }
    @include breakpoint(max-tablet-portrait) {
      margin: 0;
      max-width: 100%;
      width: auto;
    }
  }
  .article__page-category {
    
    span {
      border-radius: 50px;
      padding: 8px 16px;
    }
  }
}