
@import "../../../../styles/abstracts/_breakpoints.scss";
@import "../../../../styles/abstracts/_mixins.scss";

.signuplink {
  gap: .5rem;
  min-height: 90px;
  height: 100%;

  @include breakpoint(max-tablet-portrait) {
    flex-direction: column;
  }

  .signuplink-form {
    flex-grow: 1;


    .input-label {
      font-size: 12px;
      width: 100%;
      @include truncate();
      margin: 0;
    }

    .signuplink-content {
      padding-top: 1px;
    }

    .form-control {
      border-radius: 6px;
      border: none;
    }
  }

  button {
    top: 32px;
    position: relative;
    height: 40px;
    @include truncate();
  }
}
