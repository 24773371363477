<template>
  <TabbedPageTemplate testId="dashboard-remarks-page">
    <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'comment-exclamation' } }"
      tight>
      <template #default>
        <NdsText isRichText :text="model.description.value" />
        <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
          <NdsLoader />
        </div>
        <div v-else-if="failed" class="col-12 my-3">
          <Reloader @reload="getCreditInfo" />
        </div>
        <template v-else>
          <div v-if="loaded && creditReport?.ctiConsumerPaymentInformation">
            <NdsTabs v-model="tabModel" :tabs="tabs" @back="onBack" @forward="onForward">
              <template #remarks_tab1>
                <div data-testid="remarks-page-tab-1">
                  <NdsHeading title="Dina anmärkningar" type="h4" tight />
                  <template v-if="showRemarks">
                    <Remarks :show-end-date="true"
                      :complaints="creditReport?.ctiConsumerPaymentInformation?.historicalComplaintsSpecified" />
                  </template>
                  <NdsCard v-else outlined tight>
                    <ZeroState icon="far fa-comment-exclamation" text="Ingen anmärkning registrerad"
                      data-testid="remarks-page-remarks-zerostate" />
                  </NdsCard>
                </div>
              </template>
              <template #remarks_tab2>
                <div data-testid="remarks-page-tab-2">
                  <template v-if="showRemarksKfm">
                    <RemarksKfm :show-end-date="true" :complaints="creditReport?.ctiConsumerPaymentInformation">
                      <div class="ndsCreditChartContainer">
                        <NdsHeading title="Din skuldutveckling" type="h4" tight />
                        <NdsCreditPlotChart emptyTitle="Ingen skuldutveckling är registrerad" :chartData="chartData"
                          :rangeLimit="6" />
                      </div>
                    </RemarksKfm>
                  </template>
                  <template v-else>
                    <NdsHeading title="Aktuell skuld rapporterad från Kronofogdemyndigheten (KFM)" type="h4" tight />
                    <NdsCard outlined tight>
                      <ZeroState icon="far fa-comment-exclamation" text="Ingen skuld registrerad"
                        data-testid="remarks-page-kfm-zerostate" />
                    </NdsCard>
                  </template>
                </div>
              </template>
            </NdsTabs>
          </div>
          <NdsCard v-else outlined class="remarks-zero-state-card">
            <ZeroState icon="far fa-comment-exclamation" text="Ingen anmärkning registrerad"
              data-testid="remarks-page-zerostate" />
          </NdsCard>
          <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }"
            text="Källa: Kronofogden, tingsrätt och kreditgivare" />
        </template>
      </template>
    </NdsCard>
  </TabbedPageTemplate>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { NdsLoader, NdsCard, NdsCreditPlotChart, NdsFooter, NdsHeading, NdsText, NdsTabs, useTabs } from '@nds/vue';
import Remarks from '../components/Remarks.vue';
import RemarksKfm from '../components/RemarksKfm.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import Reloader from '../components/Reloader.vue';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
});

const store = useStore();
const creditReport = computed(() => store.state.customerCreditInfoContex.customerCreditInfo.creditReport);
const loaded = computed(() => store.state.customerCreditInfoContex.loaded);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const showRemarks = computed(() => creditReport?.value?.ctiConsumerPaymentInformation?.historicalComplaintsSpecified.length > 0);
const showRemarksKfm = computed(() => creditReport?.value?.ctiConsumerPaymentInformation?.historicalDebtDetails.length > 0);
const chartData = computed(() => ({
  hasSnapshot: false,
  label: 'Din skuld',
  entries: store.state.customerCreditInfoContex.customerCreditInfo.historicalDebtDetails.entries,
}));

const tabs = ref([{ id: 'remarks_tab1', name: 'Anmärkningar' }, { id: 'remarks_tab2', name: 'Skuld hos KFM' }]);

const { tabModel, onBack, onForward } = useTabs({
  tabs,
  defaultTabId: "remarks_tab1",
});

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
<style scoped>
.ndsCreditChartContainer {
  width: 100%;
  height: 420px;
}

.remarks-zero-state-card {
  margin-left: 12px;
  margin-right: 12px;
}
</style>
