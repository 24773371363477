﻿.overlay, .new-overlay {
  background: rgba($black, 0.25);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 155;
  display: none;
}

.locked-overlay {
  /* border: 1px solid black; */
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 100;
  display: none;
}

.confirm-purchase-overlay.new-overlay {
  .conditions {
    max-width: unset;
  }

  #orderProductPackeBtn {
    margin: 0;
  }
}

.new-overlay {
  display: none;
  /*display: flex;*/
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .popup {
    border-radius: 4px;
    background: $white;
    @include vertical-align();
    max-width: 30%;
    max-height: 650px;
    overflow: auto;

    .personalInformation {
      .form-control {
        padding: 0.8rem;
        width: 80%;

        @include breakpoint(max-phone) {
          width: 100%;
        }
      }

      .form-group label {
        color: $black;
        font-size: 16px;
      }
    }

    @include breakpoint(max-phone) {
      max-width: 95%;
      max-height: 550px;
      padding: 15px;
    }

    @include breakpoint(large-desktop) {
      max-height: 800px;
    }

    padding: 32px;
    margin: 0 auto;
    display: flow-root;

    h3 {
      padding-top: 0;
    }

    .btn-close, .btn-approve {
      cursor: pointer;
    }

    .btn-red {
      background-color: #DE4953;
      color: $white;
      border: none;
    }

    .btn-approve, .btn-orange {
      background-color: #F39000;
      color: $white;
      border: none;
    }
  }
}

.loading-overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1100;
  background: rgba(51, 51, 51, 0.8);
  color: #dedede;
  font-size: 100px;
  text-align: center;

  > div {
    @include vertical-align();
    max-width: 30%;
    margin: 0 auto;
    height: 28px;

    .progress-bar {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
}

#toast-container > div {
  opacity: 1;
}