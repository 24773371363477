﻿.dashboard__container {
  .dashboard__table {
    border: 1px solid $gallery;
    border-radius: 6px;
    margin-bottom: 8px;

    .block__enento__accordion {
      .panel-heading a:not(.collapsed) {
        p {
          background-color: $light-smoke;
        }
      }
    }
    
    .row:not(.dashboard__table-header):not(.dashboard__table-clear):not(.dashboard__table-even):not(.dashboard__table-column):nth-child(odd) {
      background-color: $light-smoke;
    }
    .row.dashboard__table-even:nth-child(even) {
      background-color: $light-smoke;
    }
    
    .dashboard__table-header {
      border-bottom: 1.5px solid $gray-scale-black;
    }

    .dashboard__table-column {
      padding: 12px 16px; 
      @include breakpoint(max-table-portrait) {
        font-size: 14px;
      }
      @include breakpoint(max-large-desktop) {
        border-bottom: 1px solid $gallery;
      }
    }
    .dashboard__table__block-wrapper {
      padding-top: 8px;
      padding-bottom: 8px;
      .dashboard__table__block {
        font-size: 0.875em;
        line-height: 22px;
        display: block;
        &:first-child {
          font-weight: 500;
          color: $night-rider;
        }
      }
      &:nth-child(2n+1) {
        background-color: $light-smoke;
      }
    }

    &.tablesaw th, &.tablesaw td {
      padding: .9em 1.1em;
    }

    .tablesaw-swipe-cellpersist {
      border-right: none;
    }

    .head {
      min-height: 3.5rem;
      align-items: center;
      display: flex;
    }

    thead {
      color: $gray;
      font-size: 0.875rem;
    }

    tbody {
      tr {
        font-size: 0.875rem;
        color: $emperor;
        /*min-height: 3.5rem;*/
        &.odd {
          background-color: $light-smoke;
        }

        td {
          border: solid 1px $light-smoke;
        }

        td:first-child {
          &.title {
            font-family: "Gilroy-Bold";
          }

          font-size: 0.875rem;
        }

        td:last-child {
          text-align: center;
        }
      }
    }

    .data-row {
      align-items: center;
      display: flex;
      min-height: 3.5rem;
      font-size: 0.875rem;
      color: $emperor;

      div:last-child {
        text-align: center;
      }

      &.even {
        background-color: $light-smoke;
      }
    }
  }

  #income-and-taxation-table, #consumer-properties-table, #remarks-table {
    .data-row div:last-child, tbody tr td:last-child {
      text-align: left;
    }
  }

  .paging-nav {
    li {
      align-items: center;
      display: flex;
      text-align: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      span {
        margin: 0 auto;
      }

      @include attention() {
        background-color: $gallery;
      }

      &.active {
        @include attention() {
          background-color: $dark-ocean;
        }
      }
    }
  }
}