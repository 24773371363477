﻿.grecaptcha-badge {
    visibility: hidden;
}
.contact-us-header {
    text-align: center;
    height: 236px;
    color: white;
    position: relative;
    background-repeat: no-repeat;
    overflow: hidden;

    .transparent-container {
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        z-index: 2;
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        padding:1rem;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        opacity: 0.6;

        @include breakpoint(max-phone) {
            width:auto;
            height:100%;
        }
    }
}
.contact-us-container {

    .card {
        h3 {
            font-size: 1.3rem;
        }

        background-color: $ice-blue;
        text-align: center;
        padding: 2rem 1rem 2rem 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        i {
            color: $ocean;
            font-weight:400;
        }

        @include breakpoint(max-phone) {
            h3 {
                font-size: 1rem;
            }
        }
    }

    .back-link, .back-link a {
        color: $dark-ocean;
        cursor: pointer;
        margin-top: 1rem;
        padding-left:1rem;
    }

    .form-header {
        text-align: center;
        padding-bottom: 2rem;
    }

    .form-block {
        background-color: $deep-ocean;
        border-radius: 6px;
        width: 100%;
        margin-bottom: 1rem;
        padding: 5rem;


        .required-label{
            &.not-pass{
            color:red;
            }
        }

        @include breakpoint(max-phone) {
            padding: 3rem 3rem 5rem 3rem;
        }

        span, label {
            color: white;
        }

        form {
            margin: 0 auto;

            input[type=text], input[type=email], textarea, select {
                width: 100%;
                border-radius: 6px;

                &.invalid{
                    border:1px solid red;
                }
            }

            .form-control {
                padding: 6px 12px;
            }

            button {
                background-color: $orangegold;

                @include breakpoint(max-phone) {
                    margin-top:1rem;
                }
            }

            a {
                cursor: pointer;
                color: $white;
            }
        }
    }

    .information-block {
        background-color: $ice-blue;
        max-width: 352px;
        border-radius: 6px;
        /*height: 490px;*/
        padding: 0.5rem 1rem 1rem 1rem;

        h3 {
            color: $orangegold;
        }
    }
}
.related-pages-container {
    background-color: $beach;
    /*height: 318px;*/
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 5rem;

    .card {
        width: 100%;
        min-height: 132px;
        background-color: white;
        display:inline-block;
        box-shadow: none;
        @include breakpoint(max-phone) {
            margin-bottom: 2rem;
        }
        .row {
            @include breakpoint(max-tablet-portrait) {
                margin: 0;
                display: block;
            }
        }

        .card-image {
            padding: 0;
            position:relative;
            min-height:132px;
            overflow:hidden;
            img {
                width: 100%;
                height: auto;
                position: absolute;
                left: 0;
                top: 0;
                @include breakpoint(tablet-portrait) {
                    width: auto;
                    height: 100%;

                }
                @include breakpoint(desktop) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .card-content {
            padding-top: 1rem;
            padding-bottom:1rem;
        }

        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        a {
            color: $night-rider;
        }
    }
}
.col-centered {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
