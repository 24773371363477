﻿.orderformblock, .digitalmailboxblock {
    .orderform {
        background: #333;
        position: relative;
        margin-bottom: 20px;
        padding: 20px;
        background: rgba(51,51,51,0.8);
        border-radius: 4px;
        
        .loggedin-text {
            color: $white;
        }
           
        h3 {
            color: $white;
            margin: 0 0 6px;
            padding: 0;
        }   

        p {
            color: $white;
            margin: 0 0 16px;
        }

        .price-inline { 
            color: $tahiti-gold;
        }

        .price {
            color: $white;
            text-align: center;

            .number {
                font-size: 32px;
                font-family: Gilroy-SemiBold, sans-serif, sans-serif;
                line-height: 32px;
                display: inline-block;
                margin-top: 13px;
            }

            .description {
                font-size: 12px;
                line-height: 12px;
            }
        }

        input {
            @include placeholder {
                font-style: italic;
                color: $gray;
            }
        }

        input:not([type='checkbox']) {
            width: 100%;
            margin: 0;
            padding: 8px;
            border: 0;
            outline: 0;
            font-size: 16px;
        }

        button {
            border: 0;
            outline: 0;
            width: 100%;

            @include breakpoint(max-tablet-portrait) {
                margin-top: 8px;
            }
        }
    } 
}
.digitalmailboxblock {
    .orderform {
        p {
            color: $tahiti-gold; 
        }
    }
}
