﻿.dashboard__container {
    .site-content {
        .tag-label-container {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .tag-label {
            font-family: Gilroy-Regular, sans-serif;
            display: inline-block;
            border: 1px solid $gray;
            border-radius: 2px;
            padding-left: 8px;
            padding-right: 8px;
            margin-right: 5px;
            color: $night-rider;
            text-transform: uppercase;
            cursor: pointer;
            margin-top: 5px;

            &.active {
                background-color: #eaeaea;
            }
        }

        .financial-article {
            .tag-label {
                cursor: unset;
            }

            .contentvideoblock {
                display: block;

                .iframe-container {
                    max-width: unset;
                    max-height: unset;
                    width: 100%;
                }

                .iframe-container iframe {
                    max-width: unset;
                    max-height: unset;
                    width: 100%;
                    height: 100%;
                }
            }

            .top-image {
                img {
                    width: 100%;
                }
            }

            .dashboard__card {
                padding: 0;
            }

            .article-back-link {
                color: $night-rider;
            }

            @include breakpoint(desktop) {
                .large-padding {
                    padding-left: 5.938rem;
                    padding-right: 5.938rem;
                }
            }

            .text-block {
                margin-bottom: 2rem;
            }

            .tag-label-container {
                padding-top: 1rem;
                padding-bottom: 2rem;
            }

            .article-ingress {
                font-size: 1.5rem;
                margin-bottom: 2rem;
            }
        }

        .financial-listing-page {

            .article-listing-header-content {
                margin-bottom: 30px;
            }

            .dashboard__card {
                margin-left: 0;
                border-radius: 6px;
                padding: 1rem;
            }

            .related-articles-section {
                .section-header {
                    display: table;

                    span {
                        display: table-cell;
                        vertical-align: middle;
                        padding-top: 8px;
                        font-size: 16px;
                    }
                }

                hr {
                    border: 1px solid #C0C0C0;
                }
            }

            .card-container {
                margin-bottom: 30px;
                cursor: pointer;
                a:hover {
                    text-decoration: none;
                }
            }

            .card {
                h5 {
                    font-size: 1rem;
                }

                background-color: $white;

                @include attention() {
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                    -webkit-transform: scale(1.02);
                    transform: scale(1.02);
                }

                img {
                    width: 100%;
                }

                .card-content {
                    padding: 1rem;
                }
                /*filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.07)), drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06)), drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));*/
                border-radius: 6px;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
            }
        }
    }
}
