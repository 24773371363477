﻿@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input,
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }
}

