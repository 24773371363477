
@import "../../../styles/abstracts/_variables.scss";

.cookie-consent-link-button {
    background: none;
    color: $dark-ocean;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:hover {
        text-decoration: underline;
    }
}
