/**
 * @license
 * MyFonts Webfont Build ID 3347056, 2017-02-20T02:55:58-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: http://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * 
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: http://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 * 
 * Webfont: Gilroy-Light by Radomir Tinkov
 * URL: http://www.myfonts.com/fonts/radomir-tinkov/gilroy/light/
 * 
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: http://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3347056
 * Licensed pageviews: 1,000,000
 * Webfonts copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * © 2017 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/331270");*/

/*Due to Figma not presenting the Gilroy font as intended, we've decided to step down a font weight according to design.
e.g We present Gilroy-Bold in Figma as Gilroy-SemiBold in css etc...*/

@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('/Static/fonts/331270_0_0.eot');
    src: url('/Static/fonts/331270_0_0.eot?#iefix') format('embedded-opentype'), url('/Static/fonts/331270_0_0.woff2') format('woff2'), url('/Static/fonts/331270_0_0.woff') format('woff'), url('/Static/fonts/331270_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('/Static/fonts/331270_1_0.eot');
    src: url('/Static/fonts/331270_1_0.eot?#iefix') format('embedded-opentype'), url('/Static/fonts/331270_1_0.woff2') format('woff2'), url('/Static/fonts/331270_1_0.woff') format('woff'), url('/Static/fonts/331270_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Light';
    src: url('/Static/fonts/331270_2_0.eot');
    src: url('/Static/fonts/331270_2_0.eot?#iefix') format('embedded-opentype'), url('/Static/fonts/331270_2_0.woff2') format('woff2'), url('/Static/fonts/331270_2_0.woff') format('woff'), url('/Static/fonts/331270_2_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: url('/Static/fonts/331270_3_0.eot');
    src: url('/Static/fonts/331270_3_0.eot?#iefix') format('embedded-opentype'), url('/Static/fonts/331270_3_0.woff2') format('woff2'), url('/Static/fonts/331270_3_0.woff') format('woff'), url('/Static/fonts/331270_3_0.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('/Static/fonts/Nunito-Regular.eot'); /* IE9 */
    src: url('/Static/fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/Nunito-Regular.woff2') format('woff2'), /* Modern Browsers */ url('/Static/fonts/Nunito-Regular.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/Nunito-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/Nunito-Regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Nunito-SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url('/Static/fonts/Nunito-600.eot'); /* IE9 */
    src: url('/Static/fonts/Nunito-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/Nunito-600.woff2') format('woff2'), /* Modern Browsers */ url('/Static/fonts/Nunito-600.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/Nunito-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/Nunito-600.svg#Nunito') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Nunito-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('/Static/fonts/Nunito-700.eot'); /* IE9 */
    src: url('/Static/fonts/Nunito-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/Nunito-700.woff2') format('woff2'), /* Modern Browsers */ url('/Static/fonts/Nunito-700.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/Nunito-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/Nunito-700.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/Static/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/Static/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/Static/fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/Static/fonts/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/Static/fonts/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('/Static/fonts/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('/Static/fonts/roboto-v29-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/Static/fonts/roboto-v29-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/roboto-v29-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/Static/fonts/roboto-v29-latin-italic.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/roboto-v29-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/roboto-v29-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('/Static/fonts/roboto-v29-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/Static/fonts/roboto-v29-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/roboto-v29-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/Static/fonts/roboto-v29-latin-500italic.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/roboto-v29-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/roboto-v29-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/Static/fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/Static/fonts/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('/Static/fonts/roboto-v29-latin-700.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/roboto-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('/Static/fonts/roboto-v29-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/Static/fonts/roboto-v29-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/Static/fonts/roboto-v29-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/Static/fonts/roboto-v29-latin-700italic.woff') format('woff'), /* Modern Browsers */ url('/Static/fonts/roboto-v29-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/Static/fonts/roboto-v29-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
