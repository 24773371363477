
@import "../../../styles/abstracts/_variables.scss";

.icon_cell_class {
  justify-items: end;
  div {
    display: flex;
    align-self: center;
  }
}
