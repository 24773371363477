
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.form-item-container label {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
}

.alert-dismissible {
    padding-left: 65px;
}

.hide {
    display: none;
}

input {
    border-color: $silver;
}

/* .form-item-container {
    max-width: 380px;
} */

.check-marks {
    list-style: none;
    overflow: auto;
    margin-left: 0;
    padding-left: 1.75rem;
}

.check-marks li {
    margin: 0.75rem 0;
    position: relative;
}

.check-marks li::before {
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    content: "\2713";
    color: #008EAA;
    position: absolute;
    font-size: .875em;
    left: -1.5rem;
    top: 4px;
}
