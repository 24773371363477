﻿table {
    font-size: 18px;

    th {
        text-align: left;
        padding:0 4px 4px;
    }

    td {
        padding:4px;
    }
}

.table-fullwidth {
    width:100%;
}

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $white;
  }
}

table.table-minuc {
    thead,
    tbody {
        th, td {
            padding: 0;
            margin: 0;
            border: none;
        }

        th {
            font-size: 20px;
        }

        td {
            font-size: 16px;
            line-height: 1.428571429;
            padding: 4px 0;
        }
    }

    thead.underlined {
        th {
            border-bottom: 2px solid #8cbb5e;
            padding-top: 15px;
        }
    }
}