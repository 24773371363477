﻿.dashboard__container {
    .site-content {
        .mobile {
            @include breakpoint(tablet-portrait) {
                display: none;
            }
        }

        .mobile-small-menu {
            display: block;
            background: $night-rider;
            padding-left: 0;
            overflow: auto;
            white-space: nowrap;

            ul {
                padding-left: 0;
                display: inline-flex;
                list-style-type: none;
                margin-bottom: 0;

                li {
                    border-bottom: 2px solid $gray;

                    &:first-child {
                        padding-left: 10px;
                    }

                    a {
                        display: inline-block;
                        color: $gray;
                    }

                    &.active {
                        border-bottom: 2px solid #F39200;

                        a {
                            color: white;
                        }
                    }

                    float: left;
                    padding: 0.5rem;
                }
            }
        }

        .mobile-menu-header {
            color: $white;

            .open-menu-modal {
                float: right;
            }

            @include breakpoint(tablet-landscape) {
                display: none;
            }


            span {
                font-family: Gilroy-SemiBold, sans-serif, sans-serif;
            }

            i.fa-angle-down {
                color: white;
            }

            width: 100%;

            &.scroll {
                position: fixed;
                z-index: 100000;
                background-color: $night-rider;
                top: 59px;
                left: 0;
                padding: 10px 10px 0 0;
                /*height: 50px;*/
                i {
                    margin: 0 10px;
                }
            }
        }

        .mobile-nav-subpages {
            color: $white;
            padding: 10px 0;

            .mobile-subpage-button {
                border: 1px #EAEAEA solid;
                border-radius: 6px;
                margin: 5px 0;
                padding: 5px;
                color: #fff;

                span {
                    font-size: 0.75rem;
                    color: #fff;
                }

                i {
                    margin-right: 5px;
                    margin-left: 5px;
                }

                &.active {
                    background-color: $emperor;
                    border: 1px $muddy-waters solid;

                    i {
                        color: $orangegold;
                    }
                }
            }
        }

        .mobile-menu {
            @include breakpoint(tablet-landscape) {
                display: none;
            }

            .mobile-subpage-button span {
                font-size: 0.75rem;
                color: #fff;
            }

            background-color: $night-rider;
            position: absolute;
            z-index: 10000;
            padding-bottom: 1rem;
            right: 0;
            top: 45px;
            width: 70%;
            /*border: 1px solid black;*/
            -webkit-box-shadow: -9px 4px 33px 1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: -9px 4px 33px 1px rgba(0, 0, 0, 0.75);
            box-shadow: -9px 4px 33px 1px rgba(0, 0, 0, 0.75);

            .close-button-container {
                width: 100%;
                height: 50px;

                .close-button {
                    float: right;
                    padding: 10px;
                    color: $white;
                }
            }

            a {
                color: $emperor;
            }

            a.extra-menu-items {
                font-family: Gilroy-SemiBold, sans-serif;
            }

            .menu {
                list-style-type: none;
                padding: 0;
                margin-bottom: 40px;

                li {
                    padding: 12px;

                    i {
                        color: $silver;
                        margin-right: 10px;
                    }

                    a {
                        color: $silver;
                    }
                }

                li.active {
                    background-color: $emperor;

                    i {
                        color: $orangegold;
                    }

                    a {
                        font-family: Gilroy-SemiBold, sans-serif, sans-serif;
                        color: $white;
                    }
                }
            }

            .extra-menu-items {
                padding-left: 15px;
            }
        }
    }
}
