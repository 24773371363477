﻿// OPACITY
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// CLEARFIX
@mixin clearfix {
&:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// BOX-SHADOW
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
-webkit-box-shadow:$top $left $blur $color #{$inset};
-moz-box-shadow:$top $left $blur $color #{$inset};
box-shadow:$top $left $blur $color #{$inset};
}

// PLACEHOLDER COLOR
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &:-ms-input-placeholder { @content }
}


// BORDER-RADIUS
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

// BORDER-RADIUS-CORNERS
@mixin border-radius-corners($top, $right, $bottom, $left) {
  -webkit-border-radius: $top $right $bottom $left;
     -moz-border-radius: $top $right $bottom $left;
      -ms-border-radius: $top $right $bottom $left;
          border-radius: $top $right $bottom $left;
}

// SIZE
@mixin size($width, $height: $width) {
      width: $width;
      height: $height;
}

// VERTICAL-ALIGN
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// ALL-ALIGN
@mixin all-align($position: relative) {
  position: $position;
  top: 50%;
  left:50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

// ATTENTION
@mixin attention() {
    /*&:hover,
    &:visited,
    &:focus,
    &:active {
        @content;
    }*/
    &:active,
    &.active {
        &:focus,
        &.focus {
            @content;
        }
    }

    &:hover,
    &.hover {
        &:active,
        &.active {
            @content;
        }
    }

    &:hover,
    &:active,
    &:focus,
    &.focus {
        @content;
    }
}

@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}