﻿.heroblock {
  .heroblock__text-wrapper {
      > hr {
          max-width: 150px;
          margin-left: 0;
          margin-top: 1.5rem;
          margin-bottom: 2rem;
          border-radius: 2px;
          border-top: 0;
          border-bottom: 4px solid $ocean;
      }
      color: $white;
  }
}

.sg-block {
    background: transparent;
    background: -moz-linear-gradient(90deg,#036D82 3.39%,rgba(3,109,130,0) 83.16%);
    background: -webkit-linear-gradient(90deg,#036D82 3.39%,rgba(3,109,130,0) 83.16%);
    background: linear-gradient(90deg,#036D82 3.39%,rgba(3,109,130,0) 83.16%);
    padding: 1.5rem;
    border-radius: 6px 0 0 6px;
    color: #fff;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: block;
    text-decoration: none;

    .sg-border-left{
        display:none;
    } 
    &:hover {
        color: $white;
        text-decoration: none;
        background: linear-gradient(90deg,#008EAA 3.39%,rgba(217,217,217,0) 83.16%);

        .sg-border-left {
            display: block;
            position: absolute;
            height: 100%;
            width: 6px;
            background: #f29008;
            top: 0;
            left: 0;
        }
    }


    h2 {
        margin-bottom: 1.75rem;
        line-height: 1;
        display: flex;
        align-items: center;
    }
}
