﻿.article__teaser__container {
  padding-bottom: 3.5rem;

  .article__teaser {
    a {
      @include attention() {
        text-decoration: none;
        .article__teaser-overlay {
          .article__teaser-img {
            opacity: 1;
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
            -webkit-transform: scale(1.08);
            transform: scale(1.08);
          } 
        }
        .article__teaser__txt-container {
          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        }
      }
      .article__teaser-overlay {
        overflow: hidden; 
        .article__teaser-img {
          opacity: 0.8;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          width: 100%;
          height: 200px;
          background-size: cover !important;
          background-position: center center !important;
          background-repeat: no-repeat !important;
          -webkit-transition: 0.4s ease;
          transition: 0.4s ease;
        }
      }
 
      .article__teaser__txt-container {
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-bottom: 2rem;
        padding: 10px 15px;
        background-color: $light-smoke;

        h2.article__teaser-heading {
          margin: 0; 
          color: $black;
          height: 62px;
          overflow: hidden;
          font-size: 1.375rem;
          line-height: 1.75rem;
          font-family: Gilroy-Semibold;
        }

        .article__teaser-description {
          color: $black;
          height: 100px;
          line-height: normal;
          display: -webkit-box; 
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .article__teaser-link {
          margin-top: 10px; 
          font-family: Gilroy-SemiBold;
        }
      }
    }
  }
}