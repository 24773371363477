
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";

.group-invite-container {
  .mobile__group__invite__member {
    a {
      position: absolute;
      right: 16px;
      top: calc(50% - 15px);
    }
  }

  .status-label {
    background-color: $light-smoke;
    border-radius: 6px;
    padding: 2px 4px;
    text-align: center;

    &.invited {
      color: $orangegold;
      background-color: rgba(242, 201, 76, 0.1);
    }

    &.active {
      color: $green-haze;
      background-color: rgba(38, 153, 82, 0.1);
    }

    &.cancelled {
      color: $monza;
      background-color: $feedback-error-300;
    }
  }

  table {
    .even {
      background-color: $white;
    }

    tr:hover td {
      background: inherit;
      color: inherit;
    }

    td {
      border-top: 0;
      padding: 11px 16px;
      font-size: 14px;
      vertical-align: middle;
    }

    tbody tr {
      background-color: $light-smoke;

      td:first-child {
        width: 18%;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }

      td:nth-child(2) {
        width: 20%;
        word-break: break-all;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }
    }
  }
}
