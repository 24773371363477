﻿// COLORS
// Named with http://chir.ag/projects/name-that-color/#BE8C64 or http://www.color-blindness.com/color-name-hue/

// UC Colors
$black: #000;
$white: #fff;
$dark: #444444;
$mine-shaft: #222;
$night-rider: #333;
$tundora: #444;
$emperor: #555;
$emperor-hover: #5555554d;
$dim-gray: #666;
$gray: #848484;
$silver: #c0c0c0;
$alto: #d6d6d6;
$gallery: #eaeaea;
$mercury: #e9e9e9;
$white-smoke: #f1f1f1;
$light-smoke: #f7f7f7;
$white-gray: #B3B3B3;
$white-graydark: #5F5F5F;
/* ---- */
// SAND ---
$muddy-waters: #BE8C64; /* base */
$bone: #dec5b1; /* 50% */
$bizarre: #efe2d8; /* 25% */
$dawn-pink: #f5eee8; /* 15% */
$sand: #BE8C64;
// BLÅ ---
$venice-blue: #0A55A0; /* base */
$polo-blue: #84aacf; /* 50% */
$periwinkle-gray: #b5cce2; /* 25% */
$botticelli: #dae6f1; /* 15% */
$cyan: #4cafc3; /* Digital Mailbox */ 

// GRÖN ---
$green-haze: #00A04B; /* base */
$vista-blue: #7FCFA5; /* 50% */
$fringy-flower: #BFE7D2; /* 25% */
$apple-green: #d9f1e4; /* 15% */
$mantis: #8cbc5e;

// RÖD ---
$monza: #e10a19; /* base */
$froly: #f0848c; /* 50% */
$azalea: #f7c2c5; /* 25% */
$cinderella: #fbdadd; /* 15% */

// ORANGE ---
$tahiti-gold: #f29008; /* base */
$manhattan: #f8c783; /* 50% */
$banana-mania: #fce3c1; /* 25% */
$half-colonial: #FDEEDA; /* 15% */
$orangegold: #F39200;
$tahiti-semi: #E49436;

$christine: #ef7c0b; // hover for $tahiti-gold with 15% $monza

//NEW COLORS
$darkorange: #D28004;
$brownorange: #B06B01;

$eclipse: #3c3c3c; /* DARK GRAY */
$nobel: #a0a0a0; /* GRAY */
$lightgray-hover: #0000000a;
$lightgray-pressed: #00000014;
$darkgray-hover: #ffffff14;
$darkgray-pressed: #ffffff29;
  
$ocean-foam: #DDEEF2;
$deep-ocean: #004755;
$ice-blue: #F0F6F8;
$rodeo-dust-hover: #F5E4DC;
$rodeo-dust: #F5EBE6;
$beach: #FAF7F1;
$beach-secondary: #FAF7F3;
$summer-sky: #8EC7D4;
$ocean: #008EAA;
$dark-ocean: #036D82;
$violet: #875681;
$feedback-success: #00A04B; 
$feedback-success-400: #9BCDA0;
$feedback-error: #DE4953;
$feedback-error-hover: #C5303A;
$feedback-error-300: #FBDADD;
$muddy: #31663C;
$yellow-500: #F2C94C;
$green-check: #9BCDA0;
$apricot: #F09678;
$lingon: #C8502D;
$gray-scale-black: #191514;