
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.page-template-tabs {
  background: $light-smoke;
  min-height: 1000px;

  .align-with-sidenav {
    background: $night-rider;
    height: 16px;

    @include breakpoint(max-tablet-portrait) {
      height: 0px;
    }
  }
}
