
@import "../../../styles/abstracts/_breakpoints.scss";

.invite-member-form-container {
  max-width: 50%;

  @include breakpoint(max-tablet-landscape) {
    max-width: 100%;
  }

  .form-group label {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
  }
}
