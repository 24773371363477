<template>
  <div class="mt-3 mb-4">
    <NdsHeading :title="chartHeader" type="h4" tight>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
        @click="() => creditDevelopmentQuestionModal.openModal()" aria-label="Förklaring Kreditkortsutveckling" />
    </NdsHeading>
    <div class="nds-credit-chart-container">
      <NdsCreditBarChart title="" emptyTitle="Ingen kreditutveckling registrerad" :chartData="creditCardData" />
    </div>
  </div>

  <NdsHeading :title="headline" type="h4" tight>
    <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
      @click="creditCardQuestionModal.openModal" aria-label="Förklaring Kontokredit" />
  </NdsHeading>

  <div class="d-flex justify-content-between mb-3">
    <div v-if="paginatedCredits.length > 0" class="d-flex mr-2 credit-summary-wrapper">
      <p class="d-flex flex-wrap m-0">
        <span class="text-nowrap mr-1">{{ debtLabel }}</span>
        <span class="color-ocean">{{ creditCardSummary }}</span>
      </p>
    </div>

    <div v-if="hasTerminatedCredits" class="ml-auto">
      <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
        variant="secondary" @click="showTerminated = !showTerminated">
        {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
      </NdsButton>
    </div>
  </div>

  <div v-if="paginatedCredits.length > 0">
    <NdsAccordion v-model="activeAccordionId" :accordions="paginatedCredits">
      <template v-for="(credit, index) in paginatedCredits" #[credit.id] :key="index">
        <CreditDetails :creditData="credit" />
      </template>
    </NdsAccordion>
  </div>
  <NdsCard v-else tight outlined>
    <ZeroState icon="far fa-credit-card" :text="zeroStateText" />
  </NdsCard>
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
  <NdsFooter class="mt-3 p-0" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige"
    tight />
  <NdsConfirmModal v-bind="creditDevelopmentQuestionModal.props" v-model="creditDevelopmentQuestionModal.isOpen.value"
    @onConfirm="creditDevelopmentQuestionModal.closeModal" @onCancel="creditDevelopmentQuestionModal.closeModal"
    @onDismiss="creditDevelopmentQuestionModal.dismissModal" />
  <NdsConfirmModal v-bind="creditCardQuestionModal.props" v-model="creditCardQuestionModal.isOpen.value"
    @onConfirm="creditCardQuestionModal.closeModal" @onCancel="creditCardQuestionModal.closeModal"
    @onDismiss="creditCardQuestionModal.dismissModal" />
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { formatToSek } from '../_helpers.js';
import { NdsCard, NdsHeading, NdsCreditBarChart, NdsButton, NdsConfirmModal, NdsIconButton, NdsAccordion, NdsFooter, NdsPagination, useConfirmModal, usePagination } from '@nds/vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import { useEbbot } from '../utils/ebbotUtils.js';
import { creditTypes, getTotalCreditDebt, formatCredits } from '../utils/creditUtils.js';
import CreditDetails from './CreditDetails.vue';

const props = defineProps({
  model: Object,
  modelv2: Object,
  report: Object,
});

const modelItem = props?.model?.creditContentArea?.expandedValue?.[2];
const chartHeader = computed(() => modelItem?.chartHeader.value);
const headline = computed(() => modelItem?.headline.value);
const debtLabel = computed(() => modelItem?.debtLabel.value);
const primaryModalHelpText = computed(() => modelItem?.primaryModalHelpText.value);
const secondaryModalHelpText = computed(() => modelItem?.secondaryModalHelpText.value);

const showTerminated = ref(true);
const hasTerminatedCredits = ref(false);

const creditCardTotal = getTotalCreditDebt(props.report, creditTypes['creditCard']);
const creditCardSummary = formatToSek(creditCardTotal);

const formattedCredits = computed(() => formatCredits(creditTypes['creditCard'], "creditCard", hasTerminatedCredits, props.report));
const filteredFormattedCredits = computed(() => (formattedCredits.value.filter(credit => (showTerminated.value ? true : !credit.toDate))));

const zeroStateText = computed(() => hasTerminatedCredits.value ? "Inga aktiva kontokrediter registrerade" : "Ingen kontokredit registrerad");

const creditCardData = computed(() => ({
  valueLabel: 'Utnyttjad kredit',
  valueTooltip: 'Utnyttjad',
  totalLabel: 'Beviljad Kredit',
  totalTooltip: 'Beviljad',
  entries: props.report.creditChartData.entries,
}));

const { showEbbot, hideEbbot } = useEbbot();

const creditDevelopmentQuestionModal = useConfirmModal({
  title: 'Kreditkortsutveckling',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: primaryModalHelpText,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const creditCardQuestionModal = useConfirmModal({
  title: 'Kontokredit',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: secondaryModalHelpText,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedCredits,
} = usePagination(filteredFormattedCredits);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
<style scoped>
.nds-credit-chart-container {
  margin-top: 16px;
  width: 100%;
  height: 320px;
}

.credit-summary-wrapper {
  flex: 1;
}
</style>