﻿.login-block {
  text-align: center;
  margin-bottom: 30px !important;
}

.datamonitor-block {

  .icon {
    width: 100%
  }

  .modal-body {
    .error-text {
      padding-top: 40px
    }
  }

  .error-text {
    padding-top: 10px
  }
}

.block-container {
  h2, h3 {
    margin: 0 0 5px 0;
  }
}
