﻿.eventheading {
    margin: 3rem 0 1.5rem;
    font-size: 1.5rem;
    font-family: Gilroy-SemiBold, sans-serif;
}

.event {
    margin: 0 0 0.5rem;
    padding: 0.625rem 3rem 0.625rem 0.625rem;
    background: $white;
    position: relative;
    z-index: 1;

    @include border-radius(0.25rem);

    .event__info {
        padding: 0 0 0 2.5rem;
        position: relative;
    }

    .event__desc {
        font-size: 1rem;
        color: $gray;
        margin: 0;
        padding: 0.125rem 0 0;
        line-height: 1.25rem;
        font-family: Gilroy-SemiBold, sans-serif;
        min-height: 2.25rem;
        text-align: left;
    }

    .event__desc span {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
    }

    .event__desc::after {
        display: inline-block;
        line-height: 2em;
        width: 0;
        content: "\00A0";
        overflow: hidden;
        vertical-align: middle;
    }

    .event__icon {
        width: 1.875rem;
        height: 1.875rem;
        line-height: 1.875rem;
        color: $white;
        font-size: 1rem;
        text-align: center;
        background: $nobel;

        @include border-radius(1.35rem);
        position: absolute;
        top: 0.25rem;
        left: 0rem;

        .fa {
            position: relative;
            left: 0;
        }
    }

    .event__content {
        padding-left: 2.5rem;
    }

    &.event--details {
        margin: 0 auto;
        max-width: 1025px;
    }
}

.event--details {
    margin: 0 auto;
    max-width: 1025px;
}

.event--completed {

    .event__content p {
        margin: 0;
    }

    .event__content,
    .event__desc {
        color: $night-rider;
    }

    .event__icon {
        background: $emperor;
    }

    .event__complete {
        position: absolute;
        top: 0.75rem;
        right: 0.625rem;
        color: $green-haze;
        font-size: 1.75rem;
        line-height: 1.5rem;
    }
}
