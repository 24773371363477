
@media (min-width: 768px) {
    .hero-img {
        background: v-bind(backgroundImageDesktop);
    }
}

@media (max-width: 767px) {
    .hero-img {
        background: v-bind(backgroundImageMobile);
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
