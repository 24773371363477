
@import "../../../../styles/abstracts/_breakpoints.scss";
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";

.order-form-block {
    background: $night-rider;
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
    background: rgba(51, 51, 51, 0.8);
    border-radius: 4px;

    .order-form-header {
        text-align: v-bind(headerTextAlignCSS);

        h3 {
            color: $white;
            margin: 0 0 6px;
            padding: 0;
        }

        p {
            color: $white;
            margin: 0 0 16px;
        }

    }

    .price-inline {
        color: $tahiti-gold;
    }

    .price-splash {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-color: $tahiti-gold;
        position: absolute;
        top: -16px;
        right: -14px;
        color: $white;
        text-align: center;

        .price-number {
            font-size: 24px;
            font-family: Gilroy-SemiBold, sans-serif, sans-serif;
            line-height: 24px;
            display: inline-block;
            margin-top: 13px;
        }

        .price-description {
            font-size: 10px;
            line-height: 10px;
        }
    }

    button {
        border: 0;
        outline: 0;
        width: 100%;

        @include breakpoint(max-tablet-portrait) {
            margin-top: 8px;
        }
    }

    .order-form-group {
        label {
            color: $white;
            font-size: 12px;
        }

        margin: 0 0 15px;

        input {
            @include placeholder {
                font-style: italic;
                color: $gray;
            }

            font-size: 16px;
            margin: 0;
            outline: 0 none;
            padding: 8px;
            width: 100%;
        }

        .is-invalid {
            color: $feedback-error;
        }
    }

    .loggedin-text {
        color: $white;
        text-align: v-bind(headerTextAlignCSS);
    }
}
