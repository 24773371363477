﻿.readmore-trigger {
    margin: 0 auto;
    text-align: center;

    span.trigger {
        display: inline-block;
        background: $white;
        padding: 10px 30px;
        position: relative;
        top: 15px;
        min-width: 150px;
        text-align: center;
        @include border-radius(20px);
        cursor: pointer;

        .text {
            font-size: 18px;
            font-family: Gilroy-SemiBold, sans-serif;
            color: #606060;
            text-transform: uppercase;
        }

        .icon {
            display: inline-block;
            width: 19px;
            height: 19px;
            background: url(/Static/img/icon-readmoreplus.png) no-repeat;
            margin-left: 8px;
            position: relative;
            top: 2px;
            -webkit-transition-duration: 0.5s;
            -moz-transition-duration: 0.5s;
            -o-transition-duration: 0.5s;
            transition-duration: 0.5s;
            -webkit-transition-property: -webkit-transform;
            -moz-transition-property: -moz-transform;
            -o-transition-property: -o-transform;
            transition-property: transform;
        }
    }
}
