﻿.related__articles__block {

    .related__articles__teaser-overlay {
        overflow: hidden;

        .related__articles__teaser-img {
            /*opacity: 0.8;*/
            width: 100%;
            background-size: cover !important;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    }

    .related__articles__teaser__txt-container {
        height: 185px;
        background-color: $white;
        padding: 1rem 1rem;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

        h4 {
            max-height: 3.3em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include breakpoint(max-tablet-portrait) {
            height: auto;
        }

        .related__articles__teaser-category {
            display: inline-block;

            span {
                font-size: 0.75em;
                background-color: $muddy;
                border-radius: 6px;
                padding: 4px 8px;
            }
        }

        .related__articles__teaser-description {
            color: $black;
            max-height: 4.3em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .related__articles__teaser-link {
            font-family: Gilroy-SemiBold, sans-serif;
        }
    }

    .related__articles__teaser {
        @include attention() {
            @include breakpoint(tablet-portrait) {
                transform: scale(1.05);
            }
        }

        h2 {
            padding-bottom: 1.5rem;
        }

        a {
            @include attention() {
                text-decoration: none;

                .related__articles__teaser-overlay {
                    .related__articles__teaser-img {
                        opacity: 1;
                    }
                }
            }

            .box-shadow {
                filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.07)) drop-shadow(0px 2px 1px rgba(0,0,0,0.06)) drop-shadow(0px 1px 3px rgba(0,0,0,0.1));
            }
        }

        .related__articles__teaser-overlay {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .related__articles__teaser-img {
                height: 200px;
            }
        }
    }

    .related__articles__teaser-single {
        h2 {
            padding-bottom: 1.5rem;
        }

        .related__articles__teaser-overlay {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;

            .related__articles__teaser-img {
                height: 330px;
            }
        }

        .related__articles__teaser__txt-container {
            padding: 2.5rem 3.5rem;
            height: 330px;
            border-top-right-radius: 6px;
            border-bottom-left-radius: 0;

            h3 {
                margin-bottom: 1.5rem;
            }

            .related__articles__teaser-description {
                -webkit-line-clamp: 4;
            }

            @include breakpoint(max-tablet-portrait) {
                height: auto;
                padding: 2rem;
            }
        }
    }

    .category-listing {
        .nav-item {
            border-radius: 24px;
            background-color: #eaeaea;

            a {
                color: black;
                font-weight: normal;
            }

            &.active {
                background-color: #f29008;

                a {
                    color: white;
                    font-weight: bold;
                }
            }
        }
    }
}
