﻿.usergraph {
    background: $white;

    .tab-pane {
        padding: 2rem 0;
    }
}

.usergraph__intro {
    background: $mercury;
    padding: 2rem 0 0;


    .row {
        margin-bottom: 0;
    }

    .usergraph__intro-heading {
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $tundora;
        margin: 0 0 0.5rem;

        @include breakpoint(tablet-portrait) {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }

    .usergraph__intro-preamble {
        margin: 0;
        font-size: 1rem;

        @include breakpoint(tablet-portrait) {
            font-size: 1.125rem;
        }
    }
}


.usergraph__nav {
    background: $mercury;
}

.usergraph__nav--desktop {
    display: none;

    @include breakpoint(tablet-portrait) {
        display: block;
    }
}

.usergraph__nav--mobile {
    background: $white;
    text-align: center;
    padding-top: 1.5rem;

    @include breakpoint(tablet-portrait) {
        display: none;
    }

    &.usergraph__nav--reports {
        border-bottom: 0;
    }
}

.usergraph__navwrap {
    display: inline-block;
    position: relative;
}

.usergraph__navtrigger {
    min-width: 240px;
    padding: 0.375rem 0;
    font-size: 0.875rem;
    font-family: Gilroy-Semibold;

    i {
        margin-right: 0.125rem;
    }
}

.usergraph__dropdown {
    min-width: 240px;
    left: 50%;
    margin-left: -120px;
    margin-top: 1rem;

    .arrow {
        left: 50%;
        margin-left: -13px;
    }

    .notification--inline {
        display: inline-block;
        width: 0.625rem;
        height: 0.625rem;
        background: $monza;
        border: 1px solid $white;
        position: relative;
        top: -0.3125rem;
        margin-left: 0.125rem;

        @include border-radius(0.3125rem);
    }
}

.usergraph__nav--pills {
    margin: 1rem 0 0.025rem;

    > li {
        margin: 0 0.75rem 0 0;

        > a {
            background: $white;
            color: $vista-blue;
            font-size: 0.875rem;
            font-family: Gilroy-SemiBold, sans-serif;
            padding: 0.3125rem 0.875rem;

            @include border-radius-corners(2px, 2px, 0, 0);

            @include attention() {
                color: $green-haze;
                background: $white;
            }
        }

        &.active {

            > a {
                color: $white;
                background: $green-haze;
                padding: 0.375rem 0.875rem 0.3125rem;

                @include attention() {
                    color: $white;
                    background: $green-haze;
                }
                /* ARROW */
                &:after {
                    top: 80%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: none;
                    border-top-color: $green-haze;
                    border-width: 12px;
                    margin-left: -12px;
                }
            }
        }

        &.notification {
            &:before {
                content: " ";
                position: absolute;
                top: -0.25rem;
                right: -0.25rem;
                display: block;
                width: 0.625rem;
                height: 0.625rem;
                background: $monza;
                z-index: 101;
                border: 1px solid $white;

                @include border-radius(0.3125rem);
            }
        }
    }
}

.usergraph__nav--reports {
    background: none;
    margin: 0.5rem 0 1rem;
    padding: 0 1rem;
    border-bottom: 1px solid $tundora;

    > li {
        > a {
            background: $tundora;
        }
    }

    .usergraph__nav--pills {
        margin-top: 0.5rem;
        padding-bottom: 0;
        margin-bottom: 0;

        li > a {
            background: $tundora;
            color: $green-haze;

            @include attention {
                background: $emperor;
                color: $green-haze;
            }
        }

        li.active > a {
            color: $white;
            background: $green-haze;
            padding: 0.3125rem 0.875rem;
        }
    }
}

.usergraph__hc {
    background: $white;
}

.usergraph__hc-nav {
    margin: 0 0 1.5rem;
    text-align: center;

    .usergraph__hc-navnext,
    .usergraph__hc-navprev {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        font-size: 1rem;
        text-align: center;
        background: $white;
        color: $black;

        @include border-radius(0.125rem);

        @include attention() {
            background: $tahiti-gold;
            color: $white;
        }
    }

    .usergraph__hc-current {
        font-size: 1.25rem;
        font-family: Gilroy-Regular;
        display: inline-block;
        margin: 0 0.25rem;
        position: relative;
    }
}

.highcharts-root {
    font-family: Gilroy-SemiBold, sans-serif;
}

.highcharts-plot-line {
    background: #f00;
}

.highcharts-axis-title,
.highcharts-credits,
.highcharts-legend {
    display: none;
}

.highcharts-data-label text {
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    fill: $white;
}

.hc-label-current text {
    font-size: 1.25rem;
    letter-spacing: 2px;
}

.hc-label-goal text {
    font-size: 0.875rem;
    letter-spacing: 2px;
    fill: $green-haze;
}

.hc-plot-up {
    stroke: $green-haze;
    stroke-width: 2px;
}

.hc-plot-down {
    stroke: $monza;
    stroke-width: 2px;
}

.hc-plot-current {
    stroke: $green-haze;
    stroke-dasharray: 4;
    stroke-width: 2px;
}

.hc-plot-goal {
    stroke: $silver;
    stroke-width: 2px;
}

.highcharts-yaxis .highcharts-tick,
.highcharts-tick {
    stroke-width: 2;
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 2;
    stroke: $gallery;
}

.highcharts-grid-line {
    fill: none;
    display: none;
}


.highcharts-axis-line,
.highcharts-tick {
    stroke: $gallery;
    stroke-width: 2;
}

.highcharts-axis-labels {
    font-size: 0.875rem;
}

.highcharts-yaxis,
.highcharts-yaxis-labels {
    @include breakpoint(max-tablet-portrait) {
        display: none;
    }
}

.highcharts-plot-line {
    stroke-opacity: 0.25;
}

.series-red .highcharts-point {
    fill: $monza;
    stroke: $monza;
}

.series-green .highcharts-point {
    fill: $green-haze;
    stroke: $green-haze;
}

.series-black .highcharts-point {
    fill: $black;
    stroke: $black;
}

.series-goal .highcharts-point {
    fill: $white;
    stroke: $apple-green;
    stroke-width: 2;
}

.series-goal .goalicon {
    color: $green-haze;
}

.series-goalicon .highcharts-point {
    fill: $green-haze;
    stroke: $green-haze;
}

.series-goalicon .highcharts-data-label i {
    color: $white;
}

.highcharts-data-label {
    font-size: 14px !important;
    margin-top: 4px;
    color: $white;
    font-family: Gilroy-SemiBold, sans-serif, sans-serif;
}

.highcharts-data-label i {
    font-size: 1.5rem;
    color: $green-haze;
}

.highcharts-data-label .goaltext {
    color: $green-haze;
}

.usergraph__hc-wrapper {
    width: 100%;
    height: 320px;

    @include breakpoint(tablet-portrait) {
        height: 400px;
    }
}
