﻿.custom-select {
    border: 1px solid $alto;
    border-radius: 3px;
    background: $white;
}

.custom-select select {
    color: $black;
    line-height: 1.3;
    border-radius: 0.25rem;
    padding: 0;
}

.custom-select:after {
    background: $tahiti-gold;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    font-family: "Font Awesome 5 Pro";
    content: "\f0dc";
    color: $white;
    padding: 0.5rem 1rem;

    @include border-radius(0.125rem);
}

.custom-select:hover {
    background: $white;
}

.custom-select:hover:after {
    background: $christine;
}

.custom-select select:focus {
    outline: none;
    color: #222;
    border-color: $white;
}

.has-error {
    .custom-select {
        border: 1px solid $monza;
        border-radius: 3px;
        background: $white;
    }
}
