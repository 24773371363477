﻿.viewStandardPage {

    .content {
        padding-top: 1rem;
        padding-bottom: 2rem;

        table {
            margin: 1.25rem 0;
        }
    }

    &.conditions .content {
        padding-bottom: 40px;
    }
} 
 