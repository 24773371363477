.error__page {

    .hero-container {
        background-image: url(/Static/svg/b2b.svg);

        &.service-unavailable {
            background-image: url(/Static/svg/b2b-blue.svg);
        }

        background-repeat: no-repeat;
        background-size: cover;
        background-position:center;
        min-height: 550px;

        .error-code {
            font-size: 96px;
            line-height: 64px;
        }

        .fa-check{
            font-size:24px;
            font-weight:500;
        }
    }
}
