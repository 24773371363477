
@import "../../../styles/abstracts/_variables.scss";

.credit-summary-value {
    @media (min-width: 992px) {
        margin-left: 7rem !important;
    }
}

.credit-page-wrapper {
    padding: 0.75rem;
}

.tabs-wrapper {
    border-top: 1px solid $gallery;
}
