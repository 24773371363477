﻿.form-group label {
  color: $white;
  font-size: 12px;
}

input[type="text"] {
  -webkit-appearance: none;
}

input[type="text"]::-ms-clear {
  display: none;
}

.order-layer input[type="radio"],
.order-layer input[type="checkbox"],
.custom-form input[type="radio"],
.custom-form input[type="checkbox"] {
  display: none;
}

.order-layer .new-overlay .productpackage {
  /* Orange Gold */
  border: 2px solid $silver;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 16px;

  h3 {
    display: contents;
    width: 225px;
  }

  .productpackage-marker {
    float: right;

    span {
      background: url(/Static/img/sprite-checkradio-new.png) no-repeat;
    }
  }
}

.order-layer .new-overlay .productpackage.active {
  background: #FAF7F1;
  /* Orange Gold */
  border: 2px solid #F39000;
}

.order-layer input[type="radio"] + label span,
.order-layer input[type="checkbox"] + label span,
.custom-form input[type="radio"] + label span,
.custom-form input[type="checkbox"] + label span {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: -3px 8px 0 0;
  vertical-align: middle;
  background: url(/Static/img/sprite-checkradio.png) no-repeat;
  cursor: pointer;
}

.order-layer input[type="radio"] + label span,
.custom-form input[type="radio"] + label span {
  background-position: 0 0;
}

.order-layer input[type="checkbox"] + label span,
.custom-form input[type="checkbox"] + label span {
  background-position: 0 -22px;
}

.order-layer input[type="radio"]:checked + label span,
.custom-form input[type="radio"]:checked + label span,
.order-layer input[type="radio"]:checked + .productpackage-marker span {
  background-position: -22px 0;
}

.order-layer input[type="checkbox"]:checked + label span,
.custom-form input[type="checkbox"]:checked + label span {
  background-position: -22px -22px;
}

.order-layer, .custom-form {

  .discount {
    text-align: center;

    h3 {
      text-align: center;
      font-size: 18px;
      font-family: Gilroy-SemiBold, sans-serif;
      margin: 30px 0 15px;
    }

    input[type="text"] {
      font-size: 18px;
    }
  }

  h3.price {
    text-align: center;
    font-size: 28px;
  }

  p.summary {
    text-align: center;
    margin: 30px 0;
    padding: 0;
    line-height: 1.5;
  }

  .payment {

    p {
      font-size: 28px;
    }

    label {
      font-size: 20px;
    }
  }

  .personalInformation {

    input[type="text"] {
      @include placeholder {
        font-style: italic;
        color: $gray;
      }
    }
  }

  .conditions {
    a {
      color: #f07d00;
      text-decoration: underline;
    }
  }

  .conditions-information {
    text-align: left;

    h2 {
      font-size: 14px;
      text-align: left;
      margin: 0 0 5px;
    }

    h3 {
      font-size: 12px;
      text-align: left;
      margin: 0 0 5px;
    }

    p, ul {
      font-size: 10px;
      line-height: 12px;
      text-align: left;
    }
  }

  .productinfo {
    h2 {
      font-size: 18px;
      margin: 20px 0;
    }
  }
}

.order-layer,
.custom-form {
  .form-group {
    margin: 0 0 15px;

    input {
      font-size: 16px;
      margin: 0;
      outline: 0 none;
      padding: 8px;
      width: 100%;
    }
  }

  input[type=checkbox] {
    display: block;
    position: absolute;
    left: -9999999px
  }
}

.form-group .input {
  position: relative;
  margin-bottom: 8px;

  input {
    border: 0;
    border-radius: 0;
  }
}

.form-group {
  .black-label {
    color: $gray-scale-black;
  }

  label {
    display: block;
    margin: 0 0 3px;
  }

  .form-check-group {
    display: flex;
    .check-input {
      height: 24px;
      width: 24px;
      margin-top: 0;
      accent-color: $dark-ocean;
      @include attention() {
        cursor: pointer;
      }
    }
  
    .check-label {
      color: $gray-scale-black;
      font-size: 16px;
      font-weight: 400;
      margin-left: 1rem;
      @include attention() {
        cursor: pointer;
      }
    }
  
  }
  .check-is-invalid {
    color: $feedback-error;
  }

} 

form.xform {
  margin: 15px 0;

  select,
  textarea,
  input[type='text'] {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #c0c0c0;
    background: $white;
    font-size: 20px;
  }

  label,
  span {
    font-size: 18px;
    display: block;
    margin: 0 0 4px;
  }

  label {
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  input.btn-orange {
    border: 0;
    background: #f07d00;
    color: $white;
    float: right;
  }

  input.btn-orange:focus,
  input.btn-orange:hover {
    background: #eb5e00;
  }

  .field-validation-error {
    float: none;
    margin: 3px 0;
  }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: relative;
  margin-left: 0;
}
