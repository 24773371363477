﻿.site-content {
    padding: 2rem 0;

    @include breakpoint(tablet-portrait) {
        padding: 4rem 0;
    }
}

.viewCreditWatch--report {
    .site-content {
        padding: 2rem 0 1rem;

        @include breakpoint(tablet-portrait) {
            padding: 4rem 0 0;
        }
    }
}