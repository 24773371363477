<template>
    <div class="mt-3 mb-4">
        <NdsHeading :title="chartHeader" type="h4" tight>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
                @click="unsecuredLoanDevelopmentQuestionModal.openModal" aria-label="Blancolånutveckling förklaring" />
        </NdsHeading>
        <div class="nds-credit-chart-container">
            <NdsCreditPlotChart title="" emptyTitle="Ingen kreditutveckling registrerad" :chartData="chartData" />
        </div>
    </div>

    <NdsHeading :title="headline" type="h4" tight>
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
            @click="unsecuredLoanQuestionModal.openModal" aria-label="Blancolån förklaring" />
    </NdsHeading>


    <div class="d-flex justify-content-between mb-3">
        <div v-if="paginatedUnsecuredLoans.length > 0" class="d-flex mr-2 credit-summary-wrapper">
            <p class="d-flex flex-wrap m-0">
                <span class="text-nowrap mr-1">{{ debtLabel }}</span>
                <span class="color-ocean">{{ unsecureCreditSummary }}</span>
            </p>
        </div>

        <div v-if="hasTerminatedUnsecuredLoans" class="ml-auto">
            <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
                variant="secondary" @click="showTerminated = !showTerminated">
                {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
            </NdsButton>
        </div>
    </div>

    <div v-if="paginatedUnsecuredLoans.length > 0">
        <NdsAccordion v-model="activeAccordionId" :accordions="paginatedUnsecuredLoans">
            <template v-for="(loan, index) in paginatedUnsecuredLoans" #[loan.id] :key="index">
                <CreditDetails :creditData="loan" />
            </template>
        </NdsAccordion>
    </div>
    <NdsCard v-else tight outlined>
        <ZeroState icon="far fa-sack-dollar" :text="zeroStateText" />
    </NdsCard>
    <div v-if="totalPages > 1" class="mt-4">
        <NdsPagination :length="totalPages" v-model="currentPage" />
    </div>
    <NdsFooter class="mt-3 p-0" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige"
        tight />
    <NdsConfirmModal v-bind="unsecuredLoanDevelopmentQuestionModal.props"
        v-model="unsecuredLoanDevelopmentQuestionModal.isOpen.value"
        @onConfirm="unsecuredLoanDevelopmentQuestionModal.closeModal"
        @onCancel="unsecuredLoanDevelopmentQuestionModal.closeModal"
        @onDismiss="unsecuredLoanDevelopmentQuestionModal.dismissModal" />
    <NdsConfirmModal v-bind="unsecuredLoanQuestionModal.props" v-model="unsecuredLoanQuestionModal.isOpen.value"
        @onConfirm="unsecuredLoanQuestionModal.closeModal" @onCancel="unsecuredLoanQuestionModal.closeModal"
        @onDismiss="unsecuredLoanQuestionModal.dismissModal" />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { NdsCard, NdsIconButton, NdsButton, NdsCreditPlotChart, NdsAccordion, NdsConfirmModal, NdsHeading, NdsFooter, NdsPagination, usePagination, useConfirmModal } from '@nds/vue';
import { formatToSek } from '../_helpers.js';
import ZeroState from "../components/myinformation/ZeroState.vue";
import { useEbbot } from '../utils/ebbotUtils.js';
import { creditTypes, getTotalCreditDebt, formatCredits } from '../utils/creditUtils.js';
import CreditDetails from './CreditDetails.vue';

const props = defineProps({
    model: Object,
    report: Object,
});

const modelItem = props?.model?.creditContentArea?.expandedValue?.[0];
const chartHeader = computed(() => modelItem?.chartHeader.value);
const headline = computed(() => modelItem?.headline.value);
const debtLabel = computed(() => modelItem?.debtLabel.value);
const primaryModalHelpText = computed(() => modelItem?.primaryModalHelpText.value);
const secondaryModalHelpText = computed(() => modelItem?.secondaryModalHelpText.value);

const showTerminated = ref(true);
const hasTerminatedUnsecuredLoans = ref(false);

const unsecureCreditTotal = getTotalCreditDebt(props.report, creditTypes['unsecuredLoan']);
const unsecureCreditSummary = formatToSek(unsecureCreditTotal);

const formattedUnsecuredLoans = computed(() => formatCredits(creditTypes['unsecuredLoan'], "unsecuredLoan", hasTerminatedUnsecuredLoans, props.report));
const filteredFormattedUnsecuredLoans = computed(() => (formattedUnsecuredLoans.value.filter(loan => (showTerminated.value ? true : !loan.toDate))));

const zeroStateText = computed(() => hasTerminatedUnsecuredLoans.value ? "Inga aktiva blancolån registrerade" : "Inga blancolån registrerade");

const chartData = computed(() => ({
    snapshotLabel: 'Nuläge',
    hasSnapshot: true,
    label: 'Utnyttjad kredit',
    entries: props.report.unsecuredLoansChartData.entries,
}));

const { showEbbot, hideEbbot } = useEbbot();

const unsecuredLoanDevelopmentQuestionModal = useConfirmModal({
    title: 'Blancolånutveckling',
    variant: 'primary',
    prefix: { prefix: "far", iconName: "question-circle" },
    confirmBtnLabel: "Stäng",
    body: {
        text: primaryModalHelpText,
        isRichText: true,
    },
    onOpenCallback: hideEbbot,
    onDismissCallback: showEbbot,
    onCloseCallback: showEbbot,
});

const unsecuredLoanQuestionModal = useConfirmModal({
    title: 'Blancolån',
    variant: 'primary',
    prefix: { prefix: "far", iconName: "question-circle" },
    confirmBtnLabel: "Stäng",
    body: {
        text: secondaryModalHelpText,
        isRichText: true,
    },
    onOpenCallback: hideEbbot,
    onDismissCallback: showEbbot,
    onCloseCallback: showEbbot,
});

const {
    currentPage,
    totalPages,
    paginatedItems: paginatedUnsecuredLoans
} = usePagination(filteredFormattedUnsecuredLoans);

const activeAccordionId = ref([]);

watch(currentPage, () => {
    activeAccordionId.value = [];
});
</script>

<style scoped>
.nds-credit-chart-container {
    margin-top: 16px;
    width: 100%;
    height: 338px;
}

.credit-summary-wrapper {
    flex: 1;
}
</style>