//TinyMCE with enento-prefix
.enento-tinymce {
    &:has(p) {
        strong {
            padding-right: 5px;
        }
    }
    h1, h2, h3, h4, h5, h6 {
        color: $night-rider;
    }

    p {
        color: $emperor;
    }

    ol {
        padding-left: 1.75rem !important;
    }

    ul {
        padding-left: 1.75rem !important;
    }

    li {
        color: $emperor;
    }

    ul.check-marks {
        list-style: none;
        overflow: auto;
        margin-left: 0;
        padding-left: 1.75rem;

        li {
            margin: 0.75rem 0;
            position: relative;

            &::before {
                font-family: "Font Awesome 5 Pro";
                display: inline-block;
                content: "\f00c";
                color: $ocean;
                position: absolute;
                font-size: .875em;
                left: -1.5rem;
                top: 4px;
            }
        }
    }

    a {
        text-decoration: underline;
    }
}

.enento-tinymce.panel-body {
    ul {
        padding-left: 2.5rem !important;
    }
    ol {
        padding-left: 2.5rem !important;
    }
    @include breakpoint(max-tablet-portrait) {
        ul {
            padding-left: 1.75rem !important;
        }
        ol {
            padding-left: 1.75rem !important;
        } 
    } 
}

a {
    color: $dark-ocean;

    &:hover {
        color: $deep-ocean;
    }
}
// remove boostrap 4.6 overrides on vuetify components
.v-btn {
    color: inherit;
    &:hover {
        color: inherit;
    }
}

.enento__link {
    text-decoration: underline;
    color: $dark-ocean;

    @include attention() {
        color: $deep-ocean;
    }
}

img {
    max-width: 100%;
    height: auto;
    display: unset;
}

.hidden {
    display: none;
}

/* EDITMODE */
.editMode .overlay {
    display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* IFRAME */

.iframe-container {
    margin: 15px 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    width: 100%;

    @include breakpoint(max-tablet-portrait) {
        height: 300px;
    }

    @include breakpoint(tablet-portrait) {
        height: 500px;
    }


    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}

.ContentLuminous {
    color: $tahiti-gold !important;

    h1, h2, h3, h4, h5, h6 {
        color: $tahiti-gold;
    }

    p {
        color: $tahiti-gold;
    }

    li {
        color: $tahiti-gold;
    }
}

.ContentBlack {
    color: $black !important;
}

.ContentWhite {
    color: $white !important;
   
    h1, h2, h3, h4, h5, h6 {
        color: $white;
    }

    p {
        color: $white;
    }

    li {
        color: $white;
    }
}

.bg-darkocean {
    background-color: $dark-ocean;

    h1, h2, h3, h4, h5, h6 {
        color: $dark-ocean;
    }

    p {
        color: $dark-ocean;
    }

    li {
        color: $dark-ocean;
    }
}

.bg-orangegold {
    background-color: $orangegold;
}

.bg-beach {
    background-color: $beach;
}

.bg-beach-secondary {
    background-color: $beach-secondary;
}

.bg-iceblue {
    background-color: $ice-blue;
}

.bg-lightsmoke {
    background-color: $light-smoke;
}

.bg-rodeodust {
    background-color: $rodeo-dust;
}

.bg-gray {
    background-color: $gray;
}

.bg-transparent {
    background-color: transparent;
}

.bg-nightrider {
    background-color: $night-rider;
}

.bg-emperor {
    background-color: $emperor;
}

.color-black {
    color: $black;

    @include attention() {
        color: $black;
    }
}

.color-nightrider {
    color: $night-rider;

    @include attention() {
        color: $night-rider;
    }
}

.color-white {
    color: $white;

    @include attention() {
        color: $white;
    }
}

.color-orangegold {
    color: $orangegold;
}

.color-gray {
    color: $gray;

    @include attention() {
        color: $gray;
    }
}

.color-gallery {
    color: $gallery;

    @include attention() {
        color: $gallery;
    }
}

.color-ocean {
    color: $ocean;
}

.color-silver {
    color: $silver;
}

.color-darkocean {
    color: $dark-ocean;

    @include attention() {
        color: $dark-ocean;
    }
}

.color-deepocean {
    color: $deep-ocean;

    @include attention() {
        color: $deep-ocean;
    }
}

.color-emperor {
    color: $emperor;

    @include attention() {
        color: $emperor;
    }
}

.color-sand {
    color: $sand;
}

.color-red {
    color: $feedback-error;

    @include attention() {
        color: $feedback-error;
    }
}

.border-color-nightrider {
    border-color: $night-rider !important;
}

.w-100-md {
    width: 100%;

    @include breakpoint(tablet-portrait) {
        width: auto;
    }
}

.contentarea-largewidth {
    max-width: none;
    margin: 0 -110px;
    width: 130%;
    height: auto;

    @include breakpoint(max-desktop) {
        margin: 0 -95px;
        width: 131%;
    }

    @include breakpoint(max-tablet-landscape) {
        margin: 0 -75px;
        width: 133%;
    }

    @include breakpoint(max-tablet-portrait) {
        margin: 0;
        max-width: 100%;
        width: auto;
    }
}

@include breakpoint(tablet-portrait) {
    .desktop {
        display: block !important;
    }

    .mobile {
        display: none !important;
    }
}

@include breakpoint(max-tablet-portrait) {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }
}

.enento__number_list {
    counter-reset: li;
    list-style-type: none;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.125em;
    padding-left: .625em;

    li {
        position: relative;
        padding: .25rem 0 1rem 1.875rem;
        line-height: 1.5em;

        strong {
            font-weight: 500;
        }

        &:has(p) {
            padding: .25rem 0 0 1.875rem;
            line-height: 1.5em;
        }

        &::before {
            content: counter(li);
            counter-increment: li;
            height: 1.5rem;
            width: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $ocean-foam;
            border-radius: 50%;
            color: $emperor;
            position: absolute;
            left: 0;
            top: .25rem;
            font-weight: 500;
            padding: 0 !important;
        }
    }
}

//TABLES IN TINYMCE (Dashboard)
.information-container {
    table {
        display: block;
        overflow-x: auto;
        border-style: hidden;
        box-shadow: 0 0 0 1px $gallery;
        border-radius: 6px;
        font-size: 14px;

        tr:nth-child(even) {
            background-color: $light-smoke;
        }

        td {
            padding: 6px 8px;
            box-shadow: 0 1px 1px -1px;
            border-style: hidden;

            &:first-child {
                font-weight: 500;
            }

            &:last-child {
                width: 100%;
            }
        }
    }
}

.payment__card-img {
    max-height: 48px;
    width: 74px;
}

.mobile-return-overview-btn {
    a:hover {
        text-decoration-line: none;
    }
}